import { useCallback } from 'react';
import { useProfile } from './UserHooks';
import { useSolar } from './SolarHooks';

const getUserData = (userProfile) => ({
  id: userProfile?.claims?.code,
  email: userProfile?.claims?.email,
  phone: userProfile?.claims?.phone_number || userProfile?.claims?.phoneNumber,
  name: userProfile?.claims?.name
});

export const useDataLayer = () => {
  const { userProfile } = useProfile();
  const isSolar = useSolar();

  const pushToDataLayer = useCallback((event, additionalData = {}, options = { isSendStore: true, clearEcommerce: false}) => {
    const { isSendStore = true, clearEcommerce = false } = options;
    
    const dataLayerEvent = {
      event,
      ...additionalData // Spread additional data to overwrite defaults
    };

    if (isSendStore) {
      dataLayerEvent['store'] = isSolar ? "solar" : "ar_condicionado";
    }

    if (userProfile) {
      const userData = getUserData(userProfile);
      dataLayerEvent['user_data'] = userData;
    }

    if (window && window.dataLayer) {
      if (clearEcommerce) {
        window.dataLayer.push({ ecommerce: null });
      }
      window.dataLayer.push(dataLayerEvent);
    } else {
      console.warn("dataLayer is not defined");
    }
  }, []);

  return { pushToDataLayer };
};