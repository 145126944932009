import React, { useState, useEffect } from "react";
import { Col, Row, FormGroup, Label, Input, Form } from "reactstrap";
import { consultaCep } from "../../../../api";
import InputMask from "react-input-mask";
import {
  validEmail,
  formatValueToPhoneNoDDD,
  onlyAcceptNumber,
  onlyNumbersAndLetters
} from "../../../../utils/otherUtils";
import { getFreteValue, addEndValido } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { toast } from "react-toastify";

export const FormProspect = ({
  changeClient,
  errosDataProspectAndPartner,
  setErrosDataProspectAndPartner,
  valTot,
  formVisible,
}) => {
  const { frete, defaultZipcode } = useSelector(state => ({
    frete: state.Order.newOrder.newOrder.frete,
    defaultZipcode: state.Layout.defaultZipcode
  }));
  const [dataProspect, setDataProspect] = useState({
    name: "",
    email: "",
    ddd: "",
    tel: "",
    cep: "",
    address: "",
    number: "",
    complement: "",
    state: "",
    city: "",
    district: "",
  });
  const dispatch = useDispatch();

  const [loadingAddress, setLoadingAddress] = useState(false);

  useEffect(() => {
    setErrosDataProspectAndPartner({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });
  }, []);

  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setDataProspect({
        ...dataProspect,
        address: "...",
        state: "...",
        city: "...",
        district: "...",
      });
      setLoadingAddress(true);
      consultaCep(cep)
        .then(res => {
          dispatch(addEndValido(res.address));

          setDataProspect({
            ...dataProspect,
            address: res.address.address,
            state: res.address.state,
            city: res.address.city,
            district: res.address.district,
            cep: cep,
          });
          getFreteValue(valTot, "02");
          setLoadingAddress(false);
        })
        .catch(error => {
          toast.error(error);
          setDataProspect({
            ...dataProspect,
            address: "",
            state: "",
            city: "",
            district: "",
          });
          setLoadingAddress(false);
        }
        );
    } else {
      setDataProspect(state => {
        return { ...state, cep: cep };
      });
    }
  };

  useEffect(() => {
    if (dataProspect.cep) {
      getCep(dataProspect.cep);
    }
  }, [dataProspect.cep]);

  useEffect(() => {
    changeClient({
      fullname: dataProspect.name || "",
      email: dataProspect.email,
      customer_address: [
        {
          zipcode: (dataProspect.cep || "").replace(/[^0-9]+/g, ""),
          district: dataProspect.district || "",
          city: (dataProspect.city || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          state: dataProspect.state || "",
          address: dataProspect.address || "",
          number: dataProspect.number || "",
          complement: dataProspect.complement || "",
        },
      ],
      customer_phones: [
        {
          ddd: dataProspect.ddd || "",
          phone: dataProspect.tel || "",
        },
      ],
    });
  }, [dataProspect]);

  const verfiyEmail = e => {
    setDataProspect({
      ...dataProspect,
      email: e.target.value.replace(/[^a-zA-Z\d\w@.]/g, ""),
    });
    const isValid = validEmail(e.target.value);
    if (isValid) {
      setErrosDataProspectAndPartner({
        ...errosDataProspectAndPartner,
        email: "",
      });
    } else {
      setErrosDataProspectAndPartner({
        ...errosDataProspectAndPartner,
        email: "Digite um email válido",
      });
    }
  };

  useEffect(() => {
    if (formVisible.partner)
    {
      setDataProspect({
        number: "",
        cep: "",
        district: "",
        city: "",
        state: ""
      });
    }
  }, [formVisible]);

  useEffect(() => {
    checkHasCep();
  }, [formVisible.prospect, defaultZipcode]);

  const checkHasCep = () => {
    if (defaultZipcode?.cep) {
      if (String(defaultZipcode.cep).replace(/[^0-9]+/g, "").length == 8) {
        getCep(defaultZipcode.cep);
      }
    }
  };

  return (
    <Row className="mt-4">
      <Col>
        <Form autoComplete="off">
          <Alert severity="info">
            Cupons e promoções não serão aplicadas em orçamentos com Prospects
          </Alert>
          <h5 className="h5 mt-3"> Dados do Prospect</h5>
          <FormGroup>
            <Row className="mt-4">
              {" "}
              <Col md={6}>
                <Label>Nome:</Label>
                <Input
                  id="name"
                  onChange={e =>
                    setDataProspect({ ...dataProspect, name: e.target.value })
                  }
                />
              </Col>{" "}
              <Col md={6}>
                <Label>E-mail:</Label>
                <Input
                  id="email"
                  value={dataProspect.email}
                  onChange={verfiyEmail}
                />
                <p className="erros">{errosDataProspectAndPartner.email}</p>
              </Col>
            </Row>
            <Row className="mt-4">
              {" "}
              <Col md={3}>
                <Label>DDD:</Label>
                <Input
                  id="ddd"
                  onChange={e =>
                    setDataProspect({
                      ...dataProspect,
                      ddd: onlyAcceptNumber(e),
                    })
                  }
                  maxLength={2}
                  value={dataProspect.ddd}
                />
              </Col>{" "}
              <Col md={9}>
                <Label>Telefone:</Label>
                <Input
                  id="tel"
                  maxLength={10}
                  onChange={e =>
                    setDataProspect({
                      ...dataProspect,
                      tel: formatValueToPhoneNoDDD(e),
                    })
                  }
                  value={dataProspect.tel}
                />
              </Col>
            </Row>
          </FormGroup>{" "}
          <h5 style={{ marginTop: 20 }} className="h5">
            {" "}
            Endereço para Entrega
          </h5>
          <FormGroup>
            <Row className="mt-4">
              <Col md={4}>
                <Label>CEP:</Label>
                <InputMask
                  className="form-control"
                  mask="99999-999"
                  id="cep"
                  onChange={e => {
                    setDataProspect({
                      ...dataProspect,
                      cep: e.target.value
                    })
                  }}
                  disabled={frete.loading || loadingAddress}
                  value={dataProspect.cep}
                />
                {frete.loading && (
                  <p style={{ marginTop: 5 }}>Calculando valor do frete...</p>
                )}
                {loadingAddress && (
                  <p style={{ marginTop: 5 }}>Buscando dados do cep...</p>
                )}
              </Col>{" "}
              <Col md={8}>
                <Label>Endereço:</Label>
                <Input
                  id="endereco"
                  value={dataProspect.address}
                  onChange={e =>
                    setDataProspect({
                      ...dataProspect,
                      address: e.target.value,
                    })
                  }
                  disabled={loadingAddress}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              {" "}
              <Col md={3}>
                <Label>Número:</Label>
                <Input
                  id="numero"
                  maxLength={6}
                  onChange={e =>
                    setDataProspect({
                      ...dataProspect,
                      number: onlyNumbersAndLetters(e.target.value),
                    })
                  }
                  value={dataProspect.number}
                  disabled={loadingAddress}
                />
              </Col>{" "}
              <Col md={9}>
                <Label>Complemento:</Label>
                <Input
                  id="complemento"
                  onChange={e =>
                    setDataProspect({
                      ...dataProspect,
                      complement: e.target.value,
                    })
                  }
                  disabled={loadingAddress}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <Label>Bairro:</Label>
                <Input
                  id="bairro"
                  value={dataProspect.district}
                  onChange={e =>
                    setDataProspect({
                      ...dataProspect,
                      district: e.target.value,
                    })
                  }
                  disabled={loadingAddress}
                />
              </Col>{" "}
            </Row>
            <Row className="mt-4">
              <Col md={9}>
                <Label>Cidade:</Label>
                <Input
                  id="cidade"
                  disabled
                  value={dataProspect.city}
                  onChange={e =>
                    setDataProspect({ ...dataProspect, city: e.target.value })
                  }
                />
              </Col>{" "}
              <Col md={3}>
                <Label>Estado:</Label>
                <Input
                  id="uf"
                  disabled
                  value={dataProspect.state}
                  onChange={e =>
                    setDataProspect({ ...dataProspect, state: e.target.value })
                  }
                />
              </Col>{" "}
            </Row>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
};
