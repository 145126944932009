import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";

import { verfyEmailExists } from "../../../store/actions";
import "flatpickr/dist/themes/material_blue.css";
import Options from "./Options";

import { FormProspect } from "./FormProspect";
import { FormPartner } from "./FormPartner";
import { FormClient } from "./FormClient";
import { TitleAndArrow } from "./TitleAndArrow";

const RegisterUser = ({
  valTot,
  changeCepCliente,
  changeClient,
  formVisible,
  setFormVisible,
  errosDataProspectAndPartner,
  setErrosDataProspectAndPartner,
  dataLeed,
  cartBranchSameCustomerUf,
  codPartner,
  setCodPartner,
}) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(true);

  //ATRIBUI TRUE OU FALSE PARA CADA SEÇÃO QUE O USUARIO CLICAR

  return (
    <Card id="cliente">
      <CardBody>
        <TitleAndArrow
          formVisible={formVisible}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <Options formVisible={formVisible} setFormVisible={setFormVisible} />
        {isOpen ? (
          <>
            {formVisible.client ? (
              <FormClient
                errosDataProspectAndPartner={errosDataProspectAndPartner}
                setErrosDataProspectAndPartner={setErrosDataProspectAndPartner}
                formVisible={formVisible}
                setFormVisible={setFormVisible}
                changeCepCliente={changeCepCliente}
                changeClient={changeClient}
                valTot={valTot}
              />
            ) : formVisible.prospect ? (
              <FormProspect
                changeClient={changeClient}
                errosDataProspectAndPartner={errosDataProspectAndPartner}
                setErrosDataProspectAndPartner={setErrosDataProspectAndPartner}
                valTot={valTot}
                formVisible={formVisible}
              />
            ) : (
              formVisible.partner && (
                <FormPartner
                  changeClient={changeClient}
                  errosDataProspectAndPartner={errosDataProspectAndPartner}
                  setErrosDataProspectAndPartner={setErrosDataProspectAndPartner}
                  valTot={valTot}
                  codPartner={setCodPartner}
                />
              )
            )}
          </>
        ) : (
          <div />
        )}
      </CardBody>
    </Card>
  );
};

export default RegisterUser;
