import React from "react";
import { Col, FormGroup, Input, Row, Label } from "reactstrap";

const RuralOrRiverShipping = ({ 
    setRuralShippingValue,
    ruralShippingValue,
    riverShippingValue,
    setRiverShippingValue,
    setRuralOrRiverShippingValue,
    ruralOrRiverShippingValue,
}) => {
  return (
    <Col>
      <Row className="mt-3">
        <h4
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#202833",
            marginBottom: 15,
          }}
        >
          O transporte será realizado em área rural e/ou fluvial?
        </h4>
        <FormGroup check>
          <Row className="justify-content-start ms-1">
            <Col md={3}>
              <Input
                type={"radio"}
                name={"ruralOrRiverShipping"}
                id={"ruralOrRiverShippingConfirm"}
                className={"modalidade"}
                checked={ruralOrRiverShippingValue}
                onChange={() => setRuralOrRiverShippingValue(true)} // Define explicitamente como true
              />
              <Label
                htmlFor="ruralOrRiverShippingConfirm"
                className={ruralOrRiverShippingValue ? "label-prazo-active" : ""}
                style={{ marginLeft: 10, fontWeight: 400 }}
              >
                SIM
              </Label>
            </Col>
            <Col md={3}>
              <Input
                type={"radio"}
                name={"ruralOrRiverShipping"}
                id={"ruralOrRiverShippingDeny"}
                className={"modalidade"}
                checked={!ruralOrRiverShippingValue} // Define explicitamente como false
                onChange={() => setRuralOrRiverShippingValue(false)} // Define explicitamente como false
              />
              <Label
                htmlFor="ruralOrRiverShippingDeny"
                className={!ruralOrRiverShippingValue ? "label-prazo-active" : ""}
                style={{ marginLeft: 10, fontWeight: 400 }}
              >
                NÃO
              </Label>
            </Col>

            {ruralOrRiverShippingValue && (
              <Row className="rounded-3" style={{ backgroundColor: "#7979791a" }}>
                <p className="pt-2" style={{ fontWeight: 'bold', marginBottom: 5 }}>
                  Selecione as áreas de transporte aplicáveis
                </p>
                <Col md={6} className="ms-3" style={{ marginTop: 10 }}>
                  <Input
                    type={"checkbox"}
                    id={"option1"}
                    className={"additional-checkbox"}
                    checked={ruralShippingValue} // Define explicitamente como false
                    onChange={() => setRuralShippingValue(!ruralShippingValue)} // Define explicitamente como false
                  />
                  <Label htmlFor="option1" style={{ marginLeft: 10 }}>
                    Área Rural
                  </Label>
                  <br />
                  <Input
                    type={"checkbox"}
                    id={"option2"}
                    className={"additional-checkbox"}
                    checked={riverShippingValue} // Define explicitamente como false
                    onChange={() => setRiverShippingValue(!riverShippingValue)} // Define explicitamente como false
                  />
                  <Label htmlFor="option2" style={{ marginLeft: 10 }}>
                    Fluvial
                  </Label>
                </Col>
              </Row>
            )}
          </Row>
        </FormGroup>
      </Row>
    </Col>
  );
};

export default RuralOrRiverShipping;
