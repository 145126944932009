export const SellerActionTypes = {
    API_RESPONSE_SUCCESS: "@@seller/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR: "@@seller/API_RESPONSE_ERROR",
    LIST_SELLERS: "@@seller/LIST_SELLERS",
    GET_SELLER: "@@seller/GET_SELLER",
    RETRIEVE_SELLER: "@@seller/RETRIEVE_SELLER",
    UPDATE_SELLER: "@@seller/UPDATE_SELLER",
    DELETE_TELEPHONE: "@@seller/DELETE_TELEPHONE",
    ADD_TELEPHONE: "@@seller/ADD_TELEPHONE",
    UPDATE_TELEPHONE: "@@seller/UPDATE_TELEPHONE"
}