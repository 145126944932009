import * as moment from "moment";

/** set Date formate */
const formateDate = (date, format) => {
  if (!date) {
    return "";
  }

  const dateFormat = format ? format : "DD MMM Y";
  const date1 = moment(new Date(date)).format(dateFormat);
  
  return date1;
};

const formatDate = (date, format) => {
  if (!date) {
    return "";
  }

  const dateFormat = format ? format : "DD MMM Y";
  const date1 = moment(date).format(dateFormat);
  
  return date1;
};

const momentFormatDate = (date, format) => {
  if (!date) {
    return "";
  }

  const dateFormat = format ? format : "DD/MM/YYYY";
  const date1 = moment(date).format(dateFormat);

  return date1;
};

const timeAgo = dateParam => {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === "object" ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (seconds < 5) {
    return "now";
  } else if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (seconds < 90) {
    return "about a minute ago";
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (isToday) {
    return `Today at ${formateDate(date, "hh:mm a")}`; // Today at 10:20
  } else if (isYesterday) {
    return `Yesterday at ${formateDate(date, "hh:mm a")}`; // Yesterday at 10:20
  } else if (isThisYear) {
    return `${formateDate(date, "do MMMM")} at ${formateDate(date, "hh:mm a")}`; // 10th January at 10:20
  }

  return formateDate(date, "do MMMM, yyyy"); // 10. January 2017. at 10:20
};

function formatInvertDate(date) {
  let data = date.split("");
  let ano = [];
  let mes = [];
  let dia = [];
  for (let i = 0; i < 4; i++) {
    ano.push(data[0]);
    data.shift();
  }
  for (let i = 0; i < 2; i++) {
    mes.push(data[0]);
    data.shift();
  }
  for (let i = 0; i < 2; i++) {
    dia.push(data[0]);
    data.shift();
  }
  ano = ano.join("");
  mes = mes.join("");
  dia = dia.join("");
  data = `${dia}/${mes}/${ano}`;

  if (dia == 0 || mes == 0 || ano == 0) return "";
  return data;
}

function dateNow() {
  let dataAtual = "";
  let data = new Date();
  let dia = String(data.getDate()).padStart(2, "0");
  let mes = String(data.getMonth() + 1).padStart(2, "0");
  let ano = data.getFullYear();
  return (dataAtual = dia + "/" + mes + "/" + ano);
}

export {
  formateDate,
  timeAgo,
  formatInvertDate,
  momentFormatDate,
  formatDate,
  dateNow,
};
