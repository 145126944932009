import { SellerActionTypes } from "./actionTypes";

const INIT_STATE = {
    list: {
        loading: false,
        fetch: false,
        sellers: [],
        graph: {
            protheus: 0,
            clube: 0
        }
    },
    details: {
        loading: false,
        fetch: false,
        data: {},
        error: false
    },
    sellerdetails:
    {
        loading: false,
        fetch: false,
        data: {},
        error: false
    }
};

const Seller = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SellerActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SellerActionTypes.LIST_SELLERS:
                    return {
                        ...state,
                        list: {
                            loading: false,
                            fetch: true,
                            sellers: action.payload.data.sellers,
                            graph: action.payload.data.graph
                        },
                        details: {
                            ...state.details,
                            error: false
                        }
                    };

                case SellerActionTypes.GET_SELLER:
                    return {
                        ...state,
                        details: {
                            ...state.details,
                            loading: false,
                            fetch: true,
                            data: action.payload.data
                        }
                    };
                case SellerActionTypes.RETRIEVE_SELLER:
                    return {
                        ...state,
                        sellerdetails: {
                            loading: false,
                            fetch: true,
                            data: action.payload.data
                        }
                    };
                default: return state;
            }
        case SellerActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SellerActionTypes.LIST_SELLERS:
                    return {
                        ...state,
                        list: {
                            ...state.list,
                            loading: false
                        }
                    };
                    
                case SellerActionTypes.GET_SELLER:
                    return {
                        ...state,
                        details: {
                            ...state.details,
                            loading: false,
                            fetch: false,
                            data: {},
                            error: true
                        }
                    };
                
                case SellerActionTypes.RETRIEVE_SELLER:
                    return {
                        ...state,
                        sellerdetails: {
                            loading: false,
                            fetch: false,
                            data: action.payload.data,
                            error: true
                        }
                    };

                case SellerActionTypes.UPDATE_SELLER:
                    return {
                        ...state,
                        details: {
                            ...state.details,
                            loading: false,
                            fetch: true,
                        }
                    };

                case SellerActionTypes.DELETE_TELEPHONE:
                    return {
                        ...state,
                        details: {
                            ...state.details,
                            loading: false,
                            fetch: true,
                        }
                    };

                case SellerActionTypes.ADD_TELEPHONE:
                    return {
                        ...state,
                        details: {
                            ...state.details,
                            loading: false,
                            fetch: true,
                        }
                    };

                default: return state;
            }

        case SellerActionTypes.LIST_SELLERS:
            return {
                ...state,
                list: {
                    loading: true,
                    fetch: false,
                    sellers: [],
                    graph: {
                        protheus: 0,
                        clube: 0
                    }
                }
            };

        case SellerActionTypes.GET_SELLER:
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    fetch: false,
                    data: {},
                    error: false
                }
            };

        case SellerActionTypes.RETRIEVE_SELLER:
            return {
                ...state,
                sellerdetails: {
                    loading: true,
                    fetch: false,
                    data: {},
                    error: false
                }
            };

        case SellerActionTypes.UPDATE_SELLER:
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    fetch: false,
                }
            };

        case SellerActionTypes.DELETE_TELEPHONE:
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    fetch: false,
                }
            };

        case SellerActionTypes.ADD_TELEPHONE:
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    fetch: false,
                }
            };

        case SellerActionTypes.UPDATE_TELEPHONE:
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    fetch: false,
                }
            };

        default: return state;
    }
};

export default Seller;