import React from "react";
import { publicRoutes, authProtectedRoutes } from "./routes";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";

// layouts
import NonAuthLayout from "../layouts/NonAuthLayout/index";
import VerticalLayout from "../layouts/VerticalLayout/index";
import { AuthProtected, AccessRoute } from "./AuthProtected";
import PageTracker from "./PageTracker";

const Index = () => {
  const availablePublicRoutesPaths = publicRoutes.map(r => r["path"]);
  const availableAuthRoutesPath = authProtectedRoutes.map(r => r["path"]);

  return (
    <BrowserRouter>
      <PageTracker />
      <Switch>
        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact={true}
                />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>
        <Route path={availableAuthRoutesPath}>
          <AuthProtected>
            <VerticalLayout>
              <Switch>
                {authProtectedRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={!route?.exact || false}
                  />
                ))}
                <Route path="*" render={() => <Redirect to="/dashboard" />} />
              </Switch>
            </VerticalLayout>
          </AuthProtected>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Index;
