import { CustomersActionTypes } from "./actionTypes";

export const customersApiSuccess = (actionType, data) => ({
  type: CustomersActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const customersApiFail = (actionType, error) => ({
  type: CustomersActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// customer's list
export const getCustomersList = filters => ({
  type: CustomersActionTypes.GET_CUSTOMERS_LIST,
  payload: filters,
});

// view custom er details
export const getCustomerDetails = customerId => ({
  type: CustomersActionTypes.GET_CUSTOMERS_DETAILS,
  payload: customerId,
});

// SAVE customer details
export const saveCustomerDetails = (customerId, data) => ({
  type: CustomersActionTypes.SAVE_CUSTOMERS_DETAILS,
  payload: { customerId, data },
});

// get address by zipcode
export const getAddressByZipcode = zipcode => ({
  type: CustomersActionTypes.GET_ADDRES_BY_ZIPCODE,
  payload: zipcode,
});

export const resetCustomers = (flag, value) => ({
  type: CustomersActionTypes.RESET_CUSTOMERS,
  payload: { flag, value },
});

export const getMultiTelephones = id => ({
  type: CustomersActionTypes.GET_MULTI_TELEPHONE,
  payload: id,
});

export const createOrUpdateMultiTelephones = data => ({
  type: CustomersActionTypes.SAVE_MULTITELEPHONES_DETAILS,
  payload: data,
});

export const createCustomer = data => ({
  type: CustomersActionTypes.CREATE_CUSTOMER,
  payload: data,
});
