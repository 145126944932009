import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProfileDetailsList,
  getProfileDetailsList,
  getCity,
  getState
} from "../../../store/actions";
import { Col, Row, Label, Spinner, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "flatpickr/dist/themes/material_blue.css";
import { useParams, useHistory } from "react-router-dom";
import "./personal-information.scss";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { formatToCep } from "../../../utils";
import { getAdressesFromZipCode } from "../../../api/profile";
const PersonalInformation = () => {
  const dispatch = useDispatch();
  const { profileDetails, getAllProfileLoading , city, state} = useSelector(state => ({
    profileDetails: state.Profile.profileDetails,
    getAllProfileLoading: state.Profile.getAllProfileLoading,
    city: state.Profile.city,
    state: state.Profile.state
  }));

  const history = useHistory();
  const [user, setUser] = useState({});
  const [userAddress, setUserAddress] = useState({});
  const [userInfo, setUserInfo] = useState({});
  // const [states, setStates] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    dispatch(getProfileDetailsList());
  }, []);

  useEffect(() => {
    setUser(profileDetails?.user);
    setUserAddress(profileDetails?.userAddress);
    setUserInfo(profileDetails?.userInfo);

    // setStates(Object.values(profileDetails.states || {}));
  }, [profileDetails]);

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      taxvat: getCPFOrCNPJMask(),
      cellphone: userInfo?.cellphone?.replace(
        /^(\d{2})(\d{4})(\d{4})/,
        "($1) $2-$3"
      ),
      telephone: userInfo?.telephone?.replace(
        /^(\d{2})(\d{4})(\d{4})/,
        "($1) $2-$3"
      ),
    });
  }, [userInfo?.taxvat, userInfo?.cellphone, userInfo?.telephone]);

  function getCPFOrCNPJMask() {
    const CPFCNPJ = userInfo?.taxvat;
    if (CPFCNPJ?.length < 16) {
      return CPFCNPJ.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (CPFCNPJ?.length > 20) {
      return CPFCNPJ.replace(
        /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})/,
        "$1.$2.$3/$4-$5"
      );
    }
  }

  function userChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  function userAddressChange(e) {
    setUserAddress({ ...userAddress, [e.target.name]: e.target.value });
  }

  async function getDadosFromZipCode(e) {
    const zipcode = e.target.value;
    if (zipcode.length == 8) {
      const adressesData = await getAdressesFromZipCode(zipcode);
      setUserAddress({
        ...userAddress,
        cep: zipcode.replace(/^(\d{5})(\d{3})/, "$1-$2"),
        dataFromZipCode: adressesData?.data,
      });
    } else {
      setUserAddress({ ...userAddress, cep: zipcode });
    }
  }

  function userInfoChange(e) {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  }

  function changeBirth(dateY) {
    let data = dateY.date;
    let dataFormatada = `${data.getFullYear()}-${data.getMonth() + 1}-${data
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    userInfo.birth_date = dataFormatada;
  }

  useEffect(() => {
    if (!isNaN(userAddress?.dataFromZipCode?.localidade)) { 
      dispatch(getCity(userAddress?.dataFromZipCode?.localidade));
    } else if (!isNaN(userAddress?.city)) { 
      dispatch(getCity(userAddress?.city));
    }

    if (!isNaN(userAddress?.dataFromZipCode?.uf))
    {
      dispatch(getState(userAddress?.dataFromZipCode?.uf));
    }
    else if (!isNaN(userAddress?.state))
    {
      dispatch(getState(userAddress?.state));
    }
  }, [userAddress?.city, userAddress?.dataFromZipCode?.localidade, userAddress?.dataFromZipCode?.uf, userAddress?.state]);
  

  const getCityValue = () => {
    if (!isNaN(userAddress?.dataFromZipCode?.localidade)) {
      return city?.[0]?.name;
    } else if (!isNaN(userAddress?.city)) {
      return city?.[0]?.name; 
    }
    return userAddress?.dataFromZipCode?.localidade ||
    userAddress?.city ||
    ""; 
  }; 

  const getStateValue = () => {
    if (!isNaN(userAddress?.dataFromZipCode?.uf)) {
      return state?.[0]?.name;
    } else if (!isNaN(userAddress?.state)) {
      return state?.[0]?.name; 
    }
    return userAddress?.dataFromZipCode?.uf ||
    userAddress?.state ||
    ""; 
  };

  const handleValidSubmit = () => {
    const profile = {
      user: user,
      userAddress: {
        zipcode:
          Number(userAddress?.dataFromZipCode?.cep.replace("-", "")) ||
          userAddress?.zipcode,
        state: userAddress?.dataFromZipCode?.uf || userAddress?.state,
        city: userAddress?.dataFromZipCode?.localidade || userAddress?.city,
        district: userAddress?.dataFromZipCode?.bairro || userAddress?.district,
        number: userAddress?.number || userAddress?.number,
        address:
          userAddress?.dataFromZipCode?.logradouro || userAddress?.address,
        complement: userAddress?.complement || "inexistente",
      },
      userInfo: userInfo,
    };
    dispatch(updateProfileDetailsList(profile, history));
  };

  return (
    <React.Fragment>
      <AvForm
        id="component-personal-information"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <FormGroup>
          <Row>
            <h5 className="mb-4">Seus Dados</h5>
            <Col lg={6}>
              <div className="mb-3">
                <AvField
                  tabIndex="0"
                  validate={{ required: true }}
                  label="Primeiro Nome"
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  value={user?.firstname || ""}
                  onChange={userChange}
                  name="firstname"
                />
              </div>
              <div className="mb-3">
                <AvField
                  tabIndex="3"
                  type="text"
                  className="form-control"
                  id="formrow-cpf-Input"
                  label="CPF/CNPJ"
                  disabled
                  value={userInfo?.taxvat}
                  onChange={userInfoChange}
                  name="taxvat"
                />
              </div>
              <div className="mb-3">
                <AvField
                  tabIndex="4"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: " Campo obrigatório",
                    },
                    minLength: {
                      value: 14,
                      errorMessage:
                        "Digite um número de telefone celular com a quantidade correta de digitos",
                    },
                  }}
                  type="text"
                  className="form-control"
                  label="Número Celular"
                  id="formrow-cellnumber-Input"
                  value={userInfo?.cellphone || ""}
                  maxLength={15}
                  onChange={userInfoChange}
                  name="cellphone"
                />
              </div>
              <div className="mb-3">
                <div className="mb-3">
                  <AvField
                    tabIndex="6"
                    type="select"
                    label="Gênero"
                    className="form-select form-control"
                    value={userInfo?.gender || ""}
                    onChange={userInfoChange}
                    name="gender"
                  >
                    <option>Masculino</option>
                    <option>Feminino</option>
                  </AvField>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-firstname-Input">Sobrenome</Label>
                <AvField
                  tabIndex="1"
                  required
                  type="text"
                  className="form-control"
                  id="formrow-lastname-Input"
                  value={user?.lastname || ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Campo obrigatório",
                    },
                  }}
                  onChange={userChange}
                  name="lastname"
                />
              </div>
              <div className="mb-3">
                <AvField
                  required
                  label="Código"
                  type="text"
                  className="form-control"
                  id="formrow-code-Input"
                  value={user?.code || ""}
                  onChange={userChange}
                  name="code"
                  disabled={true}
                />
              </div>
              <div className="mb-3">
                <AvField
                  tabIndex="5"
                  required
                  label="Telefone"
                  type="text"
                  className="form-control"
                  id="formrow-telephone-Input"
                  value={userInfo?.telephone}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Campo obrigatório",
                    },
                    minLength: {
                      value: 14,
                      errorMessage:
                        "Digite um número de telefone fixo com a quantidade correta de digitos",
                    },
                  }}
                  maxLength={14}
                  onChange={userInfoChange}
                  name="telephone"
                />
              </div>
              <div className="mb-3">
                <Label htmlFor="formrow-firstname-Input">
                  Data de Nascimento
                </Label>
                <Flatpickr
                  className="form-control d-block input_dln"
                  placeholder="dd M,yyyy"
                  value={userInfo?.birth_date || ""}
                  options={{
                    altInput: true,
                    locale: Portuguese,
                    altFormat: "d F, Y",
                    dateFormat: "Y-m-d",
                  }}
                  onChange={([date]) => changeBirth({ date })}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <h5 className="mb-4">Endereço</h5>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <AvField
                    required
                    label="CEP"
                    type="text"
                    className="form-control"
                    id="formrow-CEP-Input"
                    value={formatToCep(userAddress?.cep || userAddress?.zipcode)}
                    errorMessage={"Campo obrigatório!"}
                    onChange={getDadosFromZipCode}
                    disabled={(userAddress?.cep || userAddress?.zipcode) ? true : false}
                    name="zipcode"
                  />
                </div>
              </Col>
              <Col md={2}>
                <AvField
                  required
                  label="Estado"
                  type="text"
                  className="form-control"
                  id="formrow-estado-Input"
                  value={getStateValue()}
                  onChange={userAddressChange}
                  disabled
                  name="district"
                />
              </Col>
              <Col md={7}>
                <div className="mb-3">
                  <AvField
                    required
                    label="Cidade"
                    type="text"
                    className="form-control"
                    id="formrow-cidade-Input"
                    disabled
                    value={getCityValue()}
                    onChange={userAddressChange}
                    name="district"
                  />
                </div>
              </Col>
              <Col md={5}>
                <div className="mb-3">
                  <AvField
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Campo obrigatório",
                      },
                    }}
                    label="Bairro"
                    type="text"
                    className="form-control"
                    id="formrow-Bairro-Input"
                    value={
                      userAddress?.dataFromZipCode?.bairro ||
                      userAddress?.district ||
                      ""
                    }
                    onChange={userAddressChange}
                    disabled={userAddress?.dataFromZipCode?.bairro}
                    name="district"
                  />
                </div>
              </Col>
              <Col md={5}>
                <div className="mb-3">
                  <AvField
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Campo obrigatório",
                      },
                    }}
                    label="Logradouro"
                    type="text"
                    className="form-control"
                    id="formrow-Logradouro-Input"
                    value={
                      userAddress?.dataFromZipCode?.logradouro ||
                      userAddress?.address
                    }
                    disabled={userAddress?.dataFromZipCode?.logradouro}
                    onChange={userAddressChange}
                    name="address"
                  />
                </div>
              </Col>
              <Col md={2}>
                <div className="mb-3">
                  <AvField
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Campo obrigatório",
                      },
                    }}
                    label="Numero"
                    type="text"
                    className="form-control"
                    id="formrow-Numero-Input"
                    value={userAddress?.number || userAddress?.number}
                    onChange={userAddressChange}
                    name="number"
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <AvField
                    label="Complemento"
                    type="text"
                    className="form-control"
                    id="formrow-Complemento-Input"
                    value={userAddress?.complement}
                    onChange={userAddressChange}
                    name="complement"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <h5 className="mb-4">Sobre você</h5>
              <div className="mb-4">
                <textarea
                  type="textarea"
                  label="Breve Descrição"
                  rows="5"
                  placeholder="Sobre"
                  pattern="^[0-9a-zA-Z]+$"
                  id="address"
                  className="mb-1 is-untouched is-pristine av-invalid form-control"
                  value={userInfo?.about || ""}
                  onChange={userInfoChange}
                  name="about"
                />
              </div>
              <Col md={12}>
                <div className="mb-3">
                  <AvField
                    required
                    label="Link para seu ou rede social"
                    placeholder="https://meusite.com.br"
                    type="text"
                    className="form-control"
                    id="formrow-Cidade-Input"
                    value={userInfo?.website || ""}
                    onChange={userInfoChange}
                    name="website"
                  />
                </div>
              </Col>
            </Row>
            <div className="mb-4 d-flex justify-content-end">
              <Button
                type="submit"
                className="botao-alterar-senha mt-3 d-flex j-c-center btn-default"
                style={{ minWidth: 135 }}
              >
                {getAllProfileLoading ? <Spinner /> : "Salvar"}
              </Button>
            </div>
          </Row>
        </FormGroup>
      </AvForm>
    </React.Fragment>
  );
};
export default PersonalInformation;
