import React from "react";
import { Button, Col, Row } from "reactstrap";

const Options = ({ formVisible, setFormVisible }) => {
  return (
    <Row>
      {" "}
      <Col md={3} />
      <Col md={2}>
        <Button
          style={{
            width: "100%",
            background: formVisible.prospect ? "#7EACC4" : "#fff7f2",
            color: formVisible.prospect ? "#FFF" : "#7EACC4",
            border: "1px solid #7EACC4"
          }}
          onClick={() =>
            setFormVisible({
              prospect: true,
              client: false,
              partner: false,
            })
          }
        >
          Prospect
        </Button>
      </Col>
      <Col md={2}>
        <Button
          style={{
            width: "100%",
            background: formVisible.client ? "#7EACC4" : "#fff7f2",
            color: formVisible.client ? "#FFF" : "#7EACC4",
            border: "1px solid #7EACC4"
          }}
          onClick={() =>
            setFormVisible({
              prospect: false,
              client: true,
              partner: false,
            })
          }
        >
          Cliente
        </Button>
      </Col>
      <Col md={2}>
        <Button
          style={{
            width: "100%",
            background: formVisible.partner ? "#7EACC4" : "#fff7f2",
            color: formVisible.partner ? "#FFF" : "#7EACC4",
            border: "1px solid #7EACC4"
          }}
          onClick={() =>
            setFormVisible({
              prospect: false,
              client: false,
              partner: true,
            })
          }
        >
          Parceiro
        </Button>
      </Col>
      <Col md={3} />
    </Row>
  );
};

export default Options;
