import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "./style.scss";

import { useDispatch, useSelector } from "react-redux";
import { addStatus, addToCart, changeGroup, editQtdKits } from "../../../../store/actions";
import { toast } from "react-toastify";
import { getOutOfStockMessage, valueByQTD } from "../../../../utils";
import { dateNow } from "../../../../utils";
import { useProfile } from "../../../../hooks";
import { isInverter } from "../../../utils/functions/solarUtils";

function ModalEditQtd({
  isOpeModalEditQtd,
  setIsOpenModalEditQtd,
  addValTot,
  valores,
  addValoresTabela,
  valoresTabela,
  item,
  setRealPower,
}) {
  const dispatch = useDispatch();
  const [adiasQtd, setAdiasQtd] = useState([]);
  const { userProfile } = useProfile();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";
  const { newOrder, user } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      user: state.Profile.profileDetails.user,
    };
  });
  const [data, setData] = useState({
    quantidade: 1,
  });
  const userCode = user?.code ? user?.code : "";
  useEffect(() => {
    setAdiasQtd(state => [...state, item.qtd ? Math.round(item.qtd) : 1]);
  }, [valores, item, newOrder]);
  useEffect(() => {
    if (adiasQtd.length > 0) {
      setData({ quantidade: adiasQtd.at(-1) });
    }
  }, [adiasQtd]);

  const toggle = () => setIsOpenModalEditQtd(state => !state);

  const editQtd = () => {
    const stock = (item.qtdEstoque + item.qtd) - Number(data.quantidade);

    if (!Number.isInteger(Number(data.quantidade))) {
      return toast.error("Só são permitidas quantidades inteiras de produtos", {
        theme: "colored",
      });
    }

    if (data.quantidade < 1) {
      return toast.error("Digite uma quantidade maior que 0", {
        theme: "colored",
      });
    } else if (stock < 0) {
      return toast.error(getOutOfStockMessage(userProfile.claims.groups), {
        theme: "colored",
      });
    }

    const date = dateNow();
    const hours = new Date().toLocaleTimeString();

    const isMod = item?.nome?.includes("MOD.");

    if (isMod) {
      var realPowerAtt = data?.quantidade * item?.potencia;
      setRealPower(realPowerAtt);
    }

    if (isInverter(item)) {
      //adiciona quantidade no MC4 e STRIGBOX base no inversor pai
      addQtdInverterChildrenMc4andStringBox(date, hours);
    }
    
    //adiciona quantidade nos produtos
    addQtOfOtherProducts(date, hours, stock);

    toggle();
  };

  const addQtOfOtherProducts = (date, hours, stock) => {
    var valTotal = {
      ...valores,
      [item?.id ? item?.id + " " + item.nome : item.nome]: valueByQTD(
        item.precoCliente,
        data.quantidade
      ),
    };
    addValTot(valTotal);
    
    var valTabela = {
      ...valoresTabela,
      [item?.id ? item?.id + " " + item.nome : item.nome]: valueByQTD(
        item.precoCliente,
        data.quantidade
      ),
    };
    addValoresTabela(valTabela);

    var status = {
      statusName: "change",
      name: item.nome,
      date: date,
      hours: hours,
      qtdAdias: adiasQtd[0],
      qtdChange: data.quantidade,
      userCode: userCode,
      displayName: displayName,
    };
    dispatch(addStatus(status));
    dispatch(editQtdKits(item.nome, data.quantidade, stock));
  };

  const lastItemQuantity = adiasQtd[adiasQtd.length - 1];

  const addQtdInverterChildrenMc4andStringBox = (date, hours) => {
    item?.pecasVinculadas?.map(peca => {
      newOrder.addedItems.filter(item => item.produto.sku == peca.sku).map(product => {
        const productItem = product.produto;
        const initialQuantity = peca.quantityPerInverter * lastItemQuantity;
        const newQuantity = peca.quantityPerInverter * data.quantidade;
        updateItemQuantitiesInKit(initialQuantity, newQuantity, productItem, date, hours);
      });
    });
  };

  const updateItemQuantitiesInKit = (
    initialQuantity,
    changedQuantity,
    product,
    date,
    hours
  ) => {
    const currentQuantityOfItem = ((product.qtd - initialQuantity) + Number(changedQuantity));

    const stock = (product?.qtdEstoque + product?.initialQtd) - currentQuantityOfItem;

    if (stock < 0) {
      return toast.error(getOutOfStockMessage(userProfile.claims.groups), {
        theme: "colored",
      });
    }

    const valTotal = {
      ...valores,
      [product.nome]: valueByQTD(
        product.precoCliente,
        currentQuantityOfItem
      ),
    };

    addValTot(valTotal);
    const status = {
      statusName: "change",
      name: product.nome,
      date: date,
      hours: hours,
      qtdAdias: product.initialQtd,
      qtdChange: currentQuantityOfItem,
      userCode: userCode,
      displayName: displayName,
    };
    
    dispatch(addStatus(status));
    dispatch(
      editQtdKits(product.nome, currentQuantityOfItem, stock)
    );
  }

  return (
    <Modal
      className="modal-insert-product"
      isOpen={isOpeModalEditQtd}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>EDITAR QUANTIDADE</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <Label>Quantidade:</Label>
            <Input
              type="number"
              value={data.quantidade}
              onChange={e =>
                setData(state => {
                  return { ...state, quantidade: e.target.value };
                })
              }
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Fechar
        </Button>{" "}
        <Button onClick={editQtd} className="button-save">
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalEditQtd;
