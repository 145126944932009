import React, { useState, useEffect } from "react";
import { Col, Row, FormGroup, Label, Input, Spinner } from "reactstrap";
import { consultaCep, getVendedor as getVendedorApi } from "../../../../api";
import InputMask from "react-input-mask";
import { mask } from "../../../../utils";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getSeller } from '../../../../store/seller/action.js';
import { useProfile } from "../../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  validEmail,
  formatValueToPhone,
  onlyAcceptNumber,
  onlyNumbersAndLetters
} from "../../../../utils/otherUtils";
import {
  getCustomer,
  addEndValido,
  getFreteValue,
} from "../../../../store/actions";
export const FormPartner = ({
  changeClient,
  errosDataProspectAndPartner,
  setErrosDataProspectAndPartner,
  valTot,
}) => {
  const { userProfile } = useProfile();

  const dispatch = useDispatch();

  const { user, details, loading } = useSelector(state => ({
    details: state.Seller.details,
    loading: state.Seller.details.loading,
    user: state.Profile.profileDetails
  }));
  const [dataPartner, setDataPartner] = useState({
    name: "",
    email: "",
    ddd: "",
    tel: "",
    cep: "",
    address: "",
    number: "",
    complement: "",
    state: "",
    city: "",
    district: "",
  });

  const [parceiros, setParceiros] = useState([]);

  const [userParceiro, setUserParceiro] = useState(false);

  const groupParceiros = [14, 15, 16, 17, 18, 19, 20];

  const [filters, setFilters] = useState({
    vendedores: [{ codigo: "", label: "", bairro: "" }],
  });
  const filterBy = () => true;
  const [isLoadingVendedores, setIsLoadingVendedores] = useState(false);

  useEffect(() => {
    const isUserParceiro = userProfile['claims']['groups'].some(groupNumber =>
      groupParceiros.includes(groupNumber)
    );
    setUserParceiro(isUserParceiro);

  }, [userProfile]);
  
  useEffect(() => {
    if (userParceiro)
    {
      dispatch(getSeller(userProfile['claims']['code']));
    }

  }, [userParceiro, userProfile, dispatch]);

  useEffect(() => {
    setErrosDataProspectAndPartner({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });
  }, []);

  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setDataPartner({
        ...dataPartner,
        address: "...",
        state: "...",
        city: "...",
        district: "...",
        cep: cep,
      });
      consultaCep(cep)
        .then(res => {
          dispatch(addEndValido(res.address));
          setDataPartner({
            ...dataPartner,
            address: res.address.address,
            state: res.address.state,
            city: res.address.city,
            district: res.address.district,
          });
          getFreteValue(valTot, "02");
        })
        .catch(err => console.log(err));
    }
  };

  const changeVendedores = inputValue => {
    setIsLoadingVendedores(true);
    setFilters({ ...filters, vendedores: [] });

    getVendedorApi(inputValue)
      .then(res => {
        let data = [];
        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
            bairro: value.BAIRRO
          });
        });

        setFilters({ ...filters, vendedores: data });
        setIsLoadingVendedores(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    
    if (parceiros[0]?.codigo) {
      dispatch(getCustomer({ cpfcnpj: parceiros[0]?.cnpj }));
      dispatch(getSeller(parceiros[0]?.codigo));
      setDataPartner({});

    }
  }, [parceiros[0]?.codigo]);

  useEffect(() => {
    setDataPartner({
      name: details.data?.user?.NOME,
      email: details.data?.user?.EMAIL.trim() || "",
      ddd: details.data?.user?.DDD_TEL,
      tel: details.data?.user?.TELEFONE,
      cep: details.data?.user?.CEP,
      address: details.data?.user?.ENDERECO.trim().split(",")[0] || "",
      number: details.data?.user?.ENDNUM,
      complement: details.data?.user?.COMPL,
      state: details.data?.user?.ESTADO,
      city: details.data?.user?.CIDADE,
      district: details.data?.user?.BAIRRO,
    });

  }, [details]);

  useEffect(() => {
    changeClient({
      fullname: dataPartner.name || "",
      email: dataPartner.email,
      cpfcnpj: parceiros[0]?.cnpj ? parceiros[0]?.cnpj.trim() : details?.data?.user?.CPFCNPJ,
      pessoa: parceiros[0]?.cnpj.trim().length > 11 ? 'J' : 'F', 
      parceiro: parceiros[0]?.codigo,
      social_number: "", 
      customer_address: [
        {
          zipcode: (dataPartner.cep || "").replace(/[^0-9]+/g, ""),
          district: dataPartner.district || "",
          city: (dataPartner.city || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          state: dataPartner.state || "",
          address: dataPartner.address || "",
          number: dataPartner.number || "",
          complement: dataPartner.complement || "",
        },
      ],
      customer_phones: [
        {
          ddd: dataPartner.ddd || "",
          phone: dataPartner.tel || "",
        },
      ],
    });
  }, [dataPartner]);

  const verifyEmail = e => {
    setDataPartner({ ...dataPartner, email: e.target.value });
    const isValid = validEmail(e.target.value);
    if (isValid) {
      setErrosDataProspectAndPartner({
        ...errosDataProspectAndPartner,
        email: "",
      });
    } else {
      setErrosDataProspectAndPartner({
        ...errosDataProspectAndPartner,
        email: "Digite um email válido",
      });
    }
  };

  useEffect(() => {
    if (dataPartner.cep) {
      getCep(dataPartner.cep);
    }
  }, [dataPartner.cep]);

  return (
    <Row className="mt-4">
      {loading && (
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex rounded-3 justify-content-center align-items-center"
        style={{ backgroundColor: 'rgb(0 0 0 / 29%)', zIndex: 9999 }}>
          <Spinner 
            color="dark"
            style={{
              height: '45px !important',
              width: '45px !important'
            }}
          />
        </div>
      )}   
      <Col>
        <h5 className="h5"> Dados do Parceiro</h5>
        <FormGroup>
          <Label>Selecione o Parceiro:</Label>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              <AsyncTypeahead
                id="parceiro"
                className="parceiro-input"
                disabled={userParceiro}
                onChange={setParceiros}
                options={filters.vendedores}
                placeholder="Pesquisar..."
                selected={parceiros}
                minLength={3}
                filterBy={filterBy}
                onSearch={changeVendedores}
                emptyLabel="Não foi encontrado nenhum vendedor"
                promptText="Buscando vendedores"
                searchText="Buscando vendedores"
                inputProps={{
                  id: "parceiro-selecionado",
                }}
                renderMenuItemChildren={option => (
                  <div>
                    {option.codigo} {option.nreduz}
                    <div>
                      <small>{option.nome}</small>
                    </div>
                  </div>
                )}
              />
              {isLoadingVendedores ? (
                <Spinner className="loading-parceiro" />
              ) : (
                <i className="fas fa-search campo-buscar"></i>
              )}
            </Col>
            <Col md={6}>
              <Input
                placeholder="CPF/CNPJ Vendedor"
                value={mask(parceiros[0]?.cnpj ? parceiros[0]?.cnpj : (details?.data?.user?.CPFCNPJ || ""))}
                disabled={true}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-4">
            {" "}
            <Col md={6}>
              <Label>Nome:</Label>
              <Input
                id="name"
                value={dataPartner.name}
                onChange={e =>
                  setDataPartner({ ...dataPartner, name: e.target.value })
                }
              />
            </Col>{" "}
            <Col md={6}>
              <Label>E-mail:</Label>
              <Input
                id="email"
                value={dataPartner.email}
                onChange={verifyEmail}
              />
              <p className="erros">{errosDataProspectAndPartner.email}</p>
            </Col>
          </Row>
          <Row className="mt-4">
            {" "}
            <Col md={3}>
              <Label>DDD:</Label>
              <Input
                id="ddd"
                onChange={e =>
                  setDataPartner({
                    ...dataPartner,
                    ddd: onlyAcceptNumber(e),
                  })
                }
                maxLength={2}
                value={dataPartner.ddd}
              />
            </Col>{" "}
            <Col md={9}>
              <Label>Telefone:</Label>
              <Input
                id="tel"
                maxLength={9}
                onChange={e =>
                  setDataPartner({
                    ...dataPartner,
                    tel: e.target.value.replace(
                      /[^0-9]+/g,
                      ""
                    ),
                  })
                }
                value={dataPartner.tel}
              />
            </Col>
          </Row>
        </FormGroup>{" "}
        <h5 style={{ marginTop: 20 }} className="h5">
          {" "}
          Endereço para Entrega
        </h5>
        <FormGroup>
          <Row className="mt-4">
            <Col md={4}>
              <Label>CEP:</Label>
              <InputMask
                className="form-control"
                mask="99999-999"
                id="cep"
                value={dataPartner.cep}
                onChange={e => {
                  setDataPartner({
                    ...dataPartner,
                    cep: e.target.value
                  })
                }}
              />
            </Col>{" "}
            <Col md={8}>
              <Label>Endereço:</Label>
              <Input
                id="endereco"
                value={dataPartner.address}
                onChange={e =>
                  setDataPartner({ 
                    ...dataPartner, 
                    address: e.target.value 
                  })
                }
              />
            </Col>
          </Row>
          <Row className="mt-4">
            {" "}
            <Col md={3}>
              <Label>Número:</Label>
              <Input
                id="numero"
                maxLength={6}
                onChange={e =>
                  setDataPartner({
                    ...dataPartner,
                    number: onlyNumbersAndLetters(e.target.value),
                  })
                }
                value={dataPartner.number}
              />
            </Col>{" "}
            <Col md={9}>
              <Label>Complemento:</Label>
              <Input
                id="complemento"
                value={dataPartner.complement}
                onChange={e =>
                  setDataPartner({
                    ...dataPartner,
                    complement: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <Label>Bairro:</Label>
              <Input
                id="bairro"
                value={dataPartner.district}
                onChange={e =>
                  setDataPartner({ 
                    ...dataPartner, 
                    district: e.target.value 
                  })
                }
              />
            </Col>{" "}
          </Row>
          <Row className="mt-4">
            <Col md={9}>
              <Label>Cidade:</Label>
              <Input
                id="cidade"
                disabled
                value={dataPartner.city}
                onChange={e =>
                  setDataPartner({ 
                    ...dataPartner, 
                    city: e.target.value 
                  })
                }
              />
            </Col>{" "}
            <Col md={3}>
              <Label>Estado:</Label>
              <Input
                id="uf"
                disabled
                value={dataPartner.state}
                onChange={e =>
                  setDataPartner({ 
                    ...dataPartner, 
                    state: e.target.value 
                  })
                }
              />
            </Col>{" "}
          </Row>
        </FormGroup>
      </Col>
    </Row>
  );
};
