/*
convert object to array
*/
import { removeXspaces } from "./stringUtils";
const objectToUrlParams = obj => {
  const params = new URLSearchParams();
  for (const key in obj) {
    params.append(key, removeXspaces(obj[key]));
  }
  return params;
};

function mask(v) {
  if (v) {
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  }
}

function telMask(tel) {
  const isCelular = (tel?.length || 0) === 9;
  let textoAjustado = "";
  if (isCelular) {
    const parte1 = tel.slice(0, 5);
    const parte2 = tel.slice(5, 9);
    textoAjustado = `${parte1}-${parte2}`;
  } else {
    const parte1 = (tel || "").slice(0, 4);
    const parte2 = (tel || "").slice(4, 8);
    textoAjustado = `${parte1}-${parte2}`;
  }
  return textoAjustado;
}

const formatValueToPhone = e => {
  var value = e.target.value;
  var valor = value;
  var retorno = valor.replace(/\D/g, "");
  retorno = retorno.replace(/^0/, "");
  if (retorno.length > 10) {
    retorno = retorno.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (retorno.length > 5) {
    if (retorno.length == 6 && e.code == "Backspace") {
      // necessário pois senão o "-" fica sempre voltando ao dar backspace
      return;
    }
    retorno = retorno.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (retorno.length > 2) {
    retorno = retorno.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    if (retorno.length != 0) {
      retorno = retorno.replace(/^(\d*)/, "($1");
    }
  }
  return retorno;
};

const formatValueToPhoneNoDDD = e => {
  let value = e.target.value;
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

const onlyAcceptNumber = e => {
  var value = e.target.value;
  var valor = value;
  var retorno = valor.replace(/\D/g, "");

  retorno = retorno.replace(/^(\d{6}).*/, "$1");

  return retorno;
};

function validaCpfCnpj(cpf) {
  cpf = cpf?.replace(/[^\d]+/g, "");

  if (cpf?.length <= 11) {
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    )
      return false;
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return false;
    return true;
  } else {
    if (
      !cpf ||
      cpf.length != 14 ||
      cpf == "00000000000000" ||
      cpf == "11111111111111" ||
      cpf == "22222222222222" ||
      cpf == "33333333333333" ||
      cpf == "44444444444444" ||
      cpf == "55555555555555" ||
      cpf == "66666666666666" ||
      cpf == "77777777777777" ||
      cpf == "88888888888888" ||
      cpf == "99999999999999"
    )
      return false;
    var tamanho = cpf.length - 2;
    var numeros = cpf.substring(0, tamanho);
    var digitos = cpf.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;
    tamanho = tamanho + 1;
    numeros = cpf.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;
    return true;
  }
}

const validEmail = email => {
  if (email.indexOf("@") == -1 || email.indexOf(".") == -1) {
    return false;
  }
  return true;
};

const formato = {
  minimumFractionDigits: 2,
  style: "currency",
  currency: "BRL",
};

const percentageFormat = {
  style: 'percent', 
  minimumFractionDigits: 2
}

function arredondar(num) {
  return Math.round(Number(num) * 100) / 100;
}

function scrollToDiv(divName, incrementScroll = 0) {
  var myDiv = document.getElementById(divName);
  let Coordinates = myDiv.getBoundingClientRect();
  const start = Coordinates.top;
  const end = Coordinates.bottom;
  if (start >= 0 && end <= window.innerHeight) {
  } else {
    window.scrollTo(0, Coordinates.y + incrementScroll);
  }
}

const onlyNumbersAndLetters = v => {
  v = v.replace(/[^a-zA-Z0-9\s]/g, '');
  return v;
};

const copyToClipboard = str => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const makeCall = nbr => {
  window.open(`tel:${nbr}`);
};

const jurosCompostos = (inicial, tempo, taxa, acr) => {
  let temp = inicial;
  if (acr) {
    for (let i = 0; i < tempo; i++) {
      temp += temp * taxa;
    }
  } else {
    for (let i = 0; i < tempo; i++) {
      temp -= temp * taxa;
    }
  }

  return temp;
};

function isIntegrador(idCode) {
  //Não exibir para todos os usuários que possuem o code = 18, 38 e 39 (duas primeiras casas da identificação)
  var takeTheFirstTwoNumbers = idCode.substr(0, 2);
  var Idintegrator = ["18", "38", "39"];

  for (var id in Idintegrator) {
    if (Idintegrator[id] == takeTheFirstTwoNumbers) {
      return true;
    }
  }
  return false;
}

function validCep(enteredCep) {
  if (enteredCep) {
    var newValue = enteredCep
      .replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1");

    return newValue;
  }
}

export function maskTelOrCell(phoneNumber) {

  const cleaned = phoneNumber.replace(/\D/g, '');
  
  if (cleaned.length === 8) {
      return cleaned.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else if (cleaned.length === 9) {
      return cleaned.replace(/(\d{5})(\d{4})/, '$1-$2');
  } else {
    
      return phoneNumber;
  }
}

const getOutOfStockMessage = (groups) => {
  const codRevenda = ['8', '9', '14', '15', '16', '17', '18', '19', '20'];

  return groups.includes(codRevenda) 
    ? "Sem estoque para o componente solicitado. Por gentileza, entrar em contato com um consultor."
    : "Sem estoque ou previsão de entrega para o componente solicitado.";

}

const formatToCep = (number) => {

  if (number === undefined || number === null) {
      return ''; 
  }

  let numStr = number.toString();

  // Se o número tem 7 dígitos, adiciona '0' na frente
  if (numStr.length === 7) {
      numStr = '0' + numStr;
  }

  if (numStr.length === 8) {
      // Retorna o número formatado no formato CEP (XXXXX-XXX)
      return numStr.slice(0, 5) + '-' + numStr.slice(5);
  }

  return numStr;
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}


function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export {
  objectToUrlParams,
  mask,
  telMask,
  validaCpfCnpj,
  formato,
  percentageFormat,
  validEmail,
  arredondar,
  copyToClipboard,
  makeCall,
  formatValueToPhone,
  formatValueToPhoneNoDDD,
  onlyAcceptNumber,
  jurosCompostos,
  isIntegrador,
  scrollToDiv,
  validCep,
  onlyNumbersAndLetters,
  getOutOfStockMessage, 
  formatToCep,
  stringAvatar,
  stringToColor,
};
