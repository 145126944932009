import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Profile
import UserProfile from "../pages/UserProfile/index";

//Contact
import ContactUs from "../pages/ContactForm";
import LiveVideoSettings from "../pages/TvDaysLive/LiveVideoSettings";
import TvDaysLiveVideoDetails from "../pages/TvDaysLive/TvDaysLiveVideoDetails";
import TvDaysLive from "../pages/TvDaysLive";
import ImageGallery from "../pages/ImageGallery";
import ImageGalleryList from "../pages/ImageGallery/ImageGalleryList";

//Loyalty
import Loyalty from "../pages/Loyalty";

//Seller
import Seller from "../pages/Seller";
// import Customers from "../pages/Customers";
import List from "../pages/Customers/List/index";
import RegisterClient from "../pages/RegisterClient";
import EditCustomer from "../pages/Customers/Edit/index";
import Warranty from "../pages/Warranty/ConsultWarranty";
import WarrantyDetails from "../pages/Warranty/WarrantyDetails/index";
// import CreateWarranty from "../pages/Warranty/CreateWarranty/index";
import Download from "../pages/Download";

//New order
import NewOrder from "../pages/NewOrders";

//Events
import Events from "../pages/Events/Events";
import EditEvent from "../pages/Events/Events/EditEvent";

//Orders
import OrderDetails from "../pages/OrderDetails";
import Orders from "../pages/Orders";

import SmartBudget from "../pages/SmartBudget";

//Rules
import Rules from "../pages/Rules";
import RegraPromocao from "../pages/PromotionRules";

import Closures from "../pages/RTClosing/Closures";

import Cartao from "../pages/RTClosing/Cartao";
import ClosuresZcm from "../pages/RTClosing/ClosuresZcm";
import CartaoDetails from "../pages/RTClosing/Cartao/SettlementDetails";

// import ControleOrcamentos from "../pages/ControleOrcamentos";
import ConsultarOrcamento from "../pages/ConsultarOrcamento";
import ConsultarOrcamentoNovo from "../pages/ConsultarOrcamentoNovo";

import Vrf from "../pages/Vrf";

//Rewards
import Rewards from "../pages/Rewards";
import Colaboradores from "../pages/Colaboradores";
import CreateColaboradores from "../pages/CreateColaboradores";
import Orcamento from "../pages/Orcamento";

import AutoGestao from "../pages/AutoGestao";
import AutoGestaoCreate from "../pages/AutoGestaoCreate";

import CartaoInsercaoManual from "../pages/CartaoInsercaoManual";
import CartaoPlanilhas from "../pages/CartaoPlanilhas";
import CartaoCadastroPendente from "../pages/CartaoCadastroPendente";
import CartaoPagamentosPendentes from "../pages/CartaoPagamentosPendentes";

import ProdutoPromocao from "../pages/ProdutoPromocao";

import VendedoresHerarquia from "../pages/VendedoresHerarquia";

// Cadastros
import ConsultarCadastro from "../pages/Cadastros/ConsultarCadastros";
import CadastrarRevenda from "../pages/Cadastros/CadastrarRevenda";

import GoogleAds from "../pages/GoogleAds";
import EventsCreate from "../pages/EventsCreate";
import EventTeams from "../pages/EventTeams";
import ImageCreator from "../pages/ImageCreator";
import BuscarRegras from "../pages/BuscarRegras";
import GcloudPrint from "../pages/GcloudPrint";
import DfaEnable from "../pages/DfaEnable";
import DfaValidate from "../pages/DfaValidate";
import Noticias from "../pages/Noticias";
import Noticia from "../pages/Noticia";

import PostCreate from "../pages/PostCreate";

import Vendedor from "../pages/Vendedor";

import CartaoCadastro from "../pages/CartaoCadastro";

import NotFound from "../pages/NotFound";



//IMPORT PAGES SOLAR
import SolarMain from "../Solar/views/main";
import SolarPdf from "../Solar/views/pdf";
import SolarSunbeamAtYourAddress from "../Solar/views/sunbeam-at-your-address";
import SolarSmartCalculator from "../Solar/views/smart-calculator";
import SolarSortByConsumption from "../Solar/views/sort-by-consumption";
import ListLeads from "../Solar/views/list-leads";
import Lead from "../Solar/views/list-leads/Edit-View";

import SolarKitPronto from "../Solar/views/kit-pronto";
import SolarKitPersonalizado from "../Solar/views/kit-personalizado";

import ListKits from "../Solar/views/list-kits";
import ListKitsInterno from "../Solar/views/list-kits-interno";

import ChooseCompany from "../pages/Authentication/ChooseCompany/";
import Notifications from "../pages/Notifications";
import CompleteRegistration from "../pages/CompleteRegistration";
import ProductRegister from "../Solar/views/product-crud/register";
import ProductList from "../Solar/views/product-crud/list";
import Politica from "../pages/PoliticaDePrivacidade";
import TermoDeUso from "../pages/TermosDeUso";

import Manufacturers from "../pages/Manufacturers";
import ManufacturerDetails from "../pages/Manufacturers/details";

const authProtectedRoutes = [
  //ROUTERS SOLAR

  { path: "/solar/politicadeprivacidade", component: Politica },
  { path: "/termosdeuso", component: TermoDeUso },

  { path: "/solar/", component: Dashboard },
  { path: "/solar/canaldireto", component: ContactUs },
  { path: "/solar/marketing", component: ImageGallery },
  { path: "/solar-main", component: SolarMain },
  { path: "/solar/pdf/:id", component: SolarPdf },

  {
    path: "/solar/raio-solar-no-seu-endereco/:id",
    component: SolarSunbeamAtYourAddress,
  },
  {
    path: "/solar/calculadora-inteligente",
    component: SolarSmartCalculator,
  },
  {
    path: "/solar/ordenar-por-consumo",
    component: SolarSortByConsumption,
  },
  {
    path: "/solar/kits-newOrder/:token",
    component: SolarKitPronto,
  },
  {
    path: "/solar/orcamentos",
    component: ConsultarOrcamento,
  },
  {
    path: "/solar/novosOrçamentos",
    component: ConsultarOrcamentoNovo,
  },
  { path: "/solar/orcamento/:id/:codFilial", component: Orcamento },
  { path: "/solar/imageCreator", component: ImageCreator },
  { path: "/solar/pedidos", component: Orders },
  { path: "/solar/pedidos/fase/:sit", component: Orders },

  { path: "/solar/clientes", component: List },
  { path: "/solar/clientes/novo", component: RegisterClient },
  { path: "/solar/cliente/:customerId/:action", component: EditCustomer },
  { path: "/solar/cliente/:customerId", component: EditCustomer },
  { path: "/solar/fechamentos", component: Closures },
  { path: "/solar/fechamentos/cartao", component: Cartao },

  { path: "/solar/pedidos/novo", component: SolarKitPronto },
  { path: "/solar/pedidos/novo/kits/:token", component: SolarKitPersonalizado },
  { path: "/solar/pedidos/novo/kits", component: SolarKitPersonalizado },

  { path: "/solar/listar-kits", component: ListKits },
  { path: "/solar/listar-kits/:id", component: ListKitsInterno },

  { path: "/solar/pedidos/:id/order", component: OrderDetails },
  { path: "/solar/pedidos/:id/:codFilial/order", component: OrderDetails },

  { path: "/solar/events/create", component: EventsCreate },
  { path: "/solar/event_teams/create", component: EventTeams },
  { path: "/solar/events", component: Events },
  { path: "/solar/cartao/cadastro", component: CartaoCadastro },
  {
    path: "/solar/cartao/cadastro-pendente",
    component: CartaoCadastroPendente,
  },
  {
    path: "/solar/cartao/pagamentos-pendentes",
    component: CartaoPagamentosPendentes,
  },
  { path: "/solar/cartao/planilhas", component: CartaoPlanilhas },
  { path: "/solar/cartao/insercao-manual", component: CartaoInsercaoManual },

  //leads
  { path: "/solar/listar-leads", component: ListLeads },
  { path: "/solar/lead/:id", component: Lead },
  { path: "/solar/lead/:id/editar", component: Lead },

  // profile
  { path: "/solar/profile", component: UserProfile },
  { path: "/solar/notificacoes", component: Notifications },
  { path: "/solar/vendedores", component: Seller },
  { path: "/solar/vendedores/:id", component: Vendedor },
  { path: "/solar/vendedores/:id/:action", component: Vendedor },

  //CRUD - PRODUCTS
  { path: "/solar/product-crud/create", component: ProductRegister },
  { path: "/solar/product-crud/list", component: ProductList },
  { path: "/solar/product-crud/update", component: ProductRegister },

  //Rules
  { path: "/solar/listar-regras", component: Rules },
  { path: "/solar/regra-promocao", component: RegraPromocao },
  { path: "/solar/regra-promocao/:id", component: RegraPromocao },

  { path: "/solar/colaboradores", component: Colaboradores },
  { path: "/solar/colaboradores/create", component: CreateColaboradores },
  { path: "/solar/colaboradores/:id/edit", component: CreateColaboradores },

  //END OF ROUTES SOLAR
  //====================================================================================================================

  { path: "/dashboard", component: Dashboard },

  { path: "/politicadeprivacidade", component: Politica },
  { path: "/termosdeuso", component: TermoDeUso },

  { path: "/notificacoes", component: Notifications },

  //Contact
  { path: "/canaldireto", component: ContactUs },

  // profile
  { path: "/profile", component: UserProfile },

  //Live Settings
  { path: "/tvadias/live/config/:videoId?", component: LiveVideoSettings },
  { path: "/tvadias/live/:videoId", component: TvDaysLiveVideoDetails },
  { path: "/tvadias/live", component: TvDaysLive },

  //Marketing
  { path: "/marketing", component: ImageGallery },
  { path: "/marketing/:id", component: ImageGalleryList },

  //RT Closing
  { path: "/fechamentos", component: Closures },
  { path: "/fechamentos/cartao", component: Cartao },
  { path: "/fechamentos/zcm/:id", component: ClosuresZcm },
  { path: "/fechamentos/zcm_cartao/:id", component: CartaoDetails },
  //Loyalty
  { path: "/fidelidade", component: Loyalty },

  //Seller
  { path: "/vendedores", component: Seller },

  //Customers
  { path: "/cliente/:customerId/:action", component: EditCustomer },
  { path: "/cliente/:customerId", component: EditCustomer },
  // { path: "/clientes/novo", component: Customers },
  { path: "/clientes", component: List },
  { path: "/clientes/novo", component: RegisterClient },

  //Warranty
  /*  
    TODO Devido a uma ocorrência ao gerar um seguro onde o mesmo foi inserido errôneamente na base de dados do Clube, 
    foi solicitado pelo Gustavo que o submenu de "Solicitar Garantia" fosse ocultado (Manutenção #52406). 
    
    { path: "/warranty/create", component: CreateWarranty },
  */
  { path: "/warranty/:id", component: WarrantyDetails },
  { path: "/warranty", component: Warranty },

  //Download
  { path: "/downloads", component: Download },

  //Events
  { path: "/events/:id/edit", component: EditEvent },
  { path: "/events", component: Events },
  { path: "/events/create", component: EventsCreate },
  { path: "/event_teams/create", component: EventTeams },

  //New orders
  { path: "/pedidos/novo", component: NewOrder },

  //Orders
  { path: "/pedidos", component: Orders },
  { path: "/pedidos/fase/:sit", component: Orders },
  { path: "/pedidos/:id/:codFilial/order/edit", component: NewOrder },

  { path: "/pedidos/:id/:codFilial/order", component: OrderDetails },

  //Rules
  { path: "/listar-regras", component: Rules },
  { path: "/regra-promocao", component: RegraPromocao },
  { path: "/regra-promocao/:id", component: RegraPromocao },

  //Orçamentos
  { path: "/orcamentos/novo", component: NewOrder },
  { path: "/orcamentos", component: ConsultarOrcamento },
  { path: "/orcamento/:id", component: Orcamento },
  { path: "/orcamento/:id/:codFilial", component: Orcamento },
  { path: "/orcamento-inteligente", component: SmartBudget },

  //Rewards
  { path: "/rewards", component: Rewards },

  //Auto Gestão
  { path: "/tableau/dashs", component: AutoGestao },
  { path: "/tableau/dashs/create", component: AutoGestaoCreate },

  { path: "/vrf", component: Vrf },
  { path: "/colaboradores", component: Colaboradores },
  { path: "/colaboradores/create", component: CreateColaboradores },
  { path: "/colaboradores/:id/edit", component: CreateColaboradores },

  { path: "/cartao/insercao-manual", component: CartaoInsercaoManual },
  { path: "/cartao/planilhas", component: CartaoPlanilhas },
  { path: "/cartao/cadastro-pendente", component: CartaoCadastroPendente },
  { path: "/cartao/cadastro", component: CartaoCadastro },
  {
    path: "/cartao/pagamentos-pendentes",
    component: CartaoPagamentosPendentes,
  },

  { path: "/produto-promocao", component: ProdutoPromocao },

  { path: "/hierarquia", component: VendedoresHerarquia },

  { path: "/marketing/digital/g-ads", component: GoogleAds },

  { path: "/imageCreator", component: ImageCreator },

  { path: "/buscar-regras", component: BuscarRegras },

  { path: "/gcloud/print", component: GcloudPrint },

  { path: "/2fa/enable", component: DfaEnable },

  { path: "/2fa/validate", component: DfaValidate },

  { path: "/noticias", component: Noticias },

  { path: "/noticias/noticia", component: Noticia },

  { path: "/noticias/noticia", component: Noticia },

  { path: "/blog", component: Noticias },

  { path: "/posts/create", component: PostCreate },

  { path: "/vendedores/:id", component: Vendedor },
  { path: "/vendedores/:id/:action", component: Vendedor },

  // Cadastros
  { path: "/cadastro", component: ConsultarCadastro},
  { path: "/cadastro/revenda", component: CadastrarRevenda},
  { path: "/cadastro/revenda/:id", component: CadastrarRevenda},
  
  { path: "/manufacturers", component: Manufacturers },
  { path: "/manufacturers/:manufacturerId", component: ManufacturerDetails },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  { path: "/", exact: false, component: () => <Redirect to="/dashboard" /> },

  { path: "/:termos", exact: true, component: NotFound },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/reset-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/choose-company", component: ChooseCompany },
  { path: "/revenda-cadastro-obrigado", component: CompleteRegistration },
];

export { authProtectedRoutes, publicRoutes };
