import React, { useState, useEffect } from "react";
import { CardBody, Card, Input, FormGroup, Label, Spinner, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearNewOrder, getFreteValue } from "../../../store/actions";
import { useColors } from "../../../hooks/useColors";

const Frete = ({
  addFrete,
  data,
  dataEstoque,
  setPrevisao,
  getKitLoading,
  waitingToChangeCartItems,
  cepSelected,
  setCepSelected,
  getTheHighestForecastDate,
  setGetTheHighestForecastDate,
  total,
  seguro,
  isMicroInverterSelected,
  discount,
  itHasForecast
}) => {
  const dispatch = useDispatch();

  const { colorDefault } = useColors();

  const { frete, newOrder, emissaoPedido } = useSelector(state => ({
    frete: state.Order.newOrder.newOrder.frete,
    newOrder: state.Order.newOrder.newOrder,
    emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
  }));
  
  const address = frete?.address;
  const [valor, setValor] = useState(0);
  const [, setBtnFreteActive] = useState(false);
  const [totalOrderValueToCalculateShipping, setTotalOrderValueToCalculateShipping] = useState(0);
  const [zipcodeToCalculateShipping, setZipcodeTocalculateShipping] = useState();
  const [isNeedShippingUpdate, setIsNeedShippingUpdate] = useState(true);
  
  const [totalWithDiscounts, setTotalWithDiscounts] = useState(parseFloat(total) - parseFloat(discount));

  useEffect(() => {
    setTotalWithDiscounts(parseFloat(total) - parseFloat(discount));
  }, [total, discount])

  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };

  useEffect(() => {
    if (getTheHighestForecastDate) {
      setPrevisao(getTheHighestForecastDate);
    }
  }, [getTheHighestForecastDate]);

  const getGreaterPrediction = () => {
    const productsThatHaveExpectedDelivery = newOrder.addedItems.filter(
      item => item.produto?.previsao != null && itHasForecast.some(value => value.produto.sku == item.produto.sku)
    );

    if (productsThatHaveExpectedDelivery.length === 0) {
      return null;
    }

    const biggestPrediction = productsThatHaveExpectedDelivery.reduce((biggerDate, item) => {
      const predictionDate = item.produto.previsao;
      const numericPredictionDate = convertToNumericDate(predictionDate);

      if (biggerDate) {
        const numericBiggerDate = convertToNumericDate(biggerDate);
        return numericPredictionDate > numericBiggerDate ? predictionDate : biggerDate;
      }

      return predictionDate;
    }, productsThatHaveExpectedDelivery[0].produto.previsao);

    return biggestPrediction;
  };

  const convertToNumericDate = date => {
    const [dia, mes, ano] = date.split('/').map(Number);
    return ano * 10000 + mes * 100 + dia;
  }

  useEffect(() => {
    if (newOrder.addedItems.length > 1) {
      setGetTheHighestForecastDate(getGreaterPrediction());
    }
  }, [newOrder.addedItems]);

  useEffect(() => {
    dispatch(clearNewOrder());
    setValor(0);
    setBtnFreteActive(false);
    setCepSelected(1000);
  }, []);

  const shippingUpdate = () => {
    if (getKitLoading) {
      return;
    }

    if (waitingToChangeCartItems) {
      return;
    }

    if (address && totalWithDiscounts > 0) {
      setTotalOrderValueToCalculateShipping(totalWithDiscounts);
      setZipcodeTocalculateShipping(address?.zipcode);

      dispatch(
        getFreteValue(
          totalWithDiscounts + seguro,
          "02",
          isMicroInverterSelected
        )
      );
      resetShippingValues();
    }
  }

  useEffect(() => {
    setIsNeedShippingUpdate(totalWithDiscounts != totalOrderValueToCalculateShipping);
  }, [totalWithDiscounts, totalOrderValueToCalculateShipping]);

  useEffect(() => {
    setIsNeedShippingUpdate(address?.zipcode != zipcodeToCalculateShipping);
  }, [address?.zipcode, zipcodeToCalculateShipping]);

  useEffect(() => {
    if (isNeedShippingUpdate) {
      resetShippingValues();
    }
  }, [isNeedShippingUpdate]);

  const resetShippingValues = () => {
    addFrete({});
    setValor(0);
    let frete = document.getElementsByName("frete");
    for (let fre of frete) {
      fre.checked = false;
    }
  }

  const updateSelectedShipping = shippingSelected => {
    addFrete(shippingSelected);
  };

  const getDifferenceForecastDays = () => {
    const now = new Date();

    if (!getGreaterPrediction()) {
      return 0;
    }

    const [day, month, year] = getGreaterPrediction().split('/');
    const forecast = new Date(`${year}-${month}-${day}`);

    if (forecast && forecast > now) {
      const differenceInMilliseconds = forecast - now;
      const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      return differenceInDays;
    }

    return 0;
  }

  const daysForProductionAndShipping = 3;

  return (
    <Card>
      <CardBody>
        <h4
          style={{
            fontSize: 23,
            fontWeight: "bold",
            color: "#202833",
            display: "flex",
            flexDirection: "column",
          }}
        >
          Calcular frete e prazo de entrega{" "}

          <span className="text-muted" style={{ fontSize: 14, fontWeight: "normal" }}>
            <small>
              Partindo de:{" "}
              {dataEstoque?.estoque ? dataEstoque?.estoque : "São Paulo"}{" "}
            </small>
          </span>
        </h4>
        <p style={{ margin: "9px 0 0 0" }}>
          CEP:{" "}
          {frete?.address?.zipcode ? (
            <span>{frete?.address?.zipcode}</span>
          ) : (
            <small className="text-muted">Nenhum cep informado</small>
          )}
        </p>
        <p style={{ margin: "9px 0 0 0" }}>
          {frete?.address ? (
            <span>
              {frete.address.address} - {frete.address.city}/
              {frete.address.state}
            </span>
          ) : (
            <span />
          )}
        </p>

        <div>
          <hr style={{ margin: "8px 0" }} />
          <h4
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#202833",
              marginBottom: 15,
              display: "flex",
              flexDirection: "column",
            }}
          >
            Selecione a modalidade{" "}
            <span
              style={{ fontSize: 12, fontWeight: "normal", color: "#495057" }}
            >
              *Dias Úteis Após Emissão da NF-e
            </span>
          </h4>
          {frete.loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 121,
              }}
            >
              <Spinner size="sm" />
              <span style={{ color: "#555b62" }}>Buscando fretes</span>
            </div>
          ) : (
            <>
              {isNeedShippingUpdate || !frete.success  || frete.entrega.length == 0 ? (
                <>
                  <Button
                    style={{
                      backgroundColor: colorDefault,
                      color: "#FFFFFF",
                      width: "100%",
                      display: "block",
                      fontSize: 17,
                      border: "none",
                      padding: 20,
                      borderRadius: 6
                    }}
                    onClick={shippingUpdate}
                  >
                    Calcular frete
                  </Button>
                </>
              ) : (
                <div
                  onChange={e => {
                    setValor(e.target.value);
                  }}
                >
                  {frete.entrega.map((value, index) => (
                    <FormGroup check className="d-flex mt-3" key={index}>
                      <Input
                        type="radio"
                        value={value.price}
                        name="frete"
                        className="modalidade"
                        onChange={() => updateSelectedShipping(value)}
                      />
                      <Label
                        className={valor == value.price ? "label-prazo-active" : ""}
                        style={{ marginLeft: 10, fontWeight: 400 }}
                      >
                        {value.description}{" "}
                        {value.usado_percentual > 0 ? (
                          <span style={{ margin: "0 5px" }}>%</span>
                        ) : (
                          ""
                        )}{" "}
                        - *Prazo até {getDifferenceForecastDays() + daysForProductionAndShipping + value.delivery_delay} dia(s) -{" "}
                        {!value.promotionDiscount ? (
                          <>
                            {Number(value?.priceSomado ? value?.priceSomado : value.price).toLocaleString("pt-br", formato)}
                          </>
                        ) : (
                          <>
                            <del>
                              {Number(value?.priceSomado ? value?.priceSomado : value.price).toLocaleString("pt-br", formato)}
                            </del>
                            {" "}
                            {Number(Number(value?.priceSomado ? value?.priceSomado : value.price) - Number(value?.promotionDiscount)).toLocaleString("pt-br", formato)}
                          </>
                        )}
                      </Label>
                    </FormGroup>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Frete;
