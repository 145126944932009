import { apiClient2 } from "./apiCore2";

// utils
import { objectToUrlParams } from "../utils/index";
import { apiClient } from "./apiCore";

const getCustomersList = filters => {
  const baseUrl = "/clientes";
  return apiClient2.get(`${baseUrl}`, filters);
};

const getCustomerDetails = customerId => {
  const baseUrl = `api/cliente`;
  return apiClient.get(`${baseUrl}/${customerId}/editar`);
};

const saveCustomerDetails = (customerId, data) => {
  const baseUrl = `/api/cliente`;
  const config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  const params = objectToUrlParams(data);
  return apiClient.create(`${baseUrl}/${customerId}/editar`, params, config);
};


export const createCustomer = data => {
  const baseUrl = "/api/create-client";
  return apiClient.create(`${baseUrl}`, data);
};

const getAddressByZipcode = zipcode => {
  const baseUrl = `/api/consulta/cep`;
  return apiClient.create(`${baseUrl}`, zipcode);
};

const getCustomer = ({cpfcnpj}) => {
  
  const baseUrl = `/clientes?customer[CPFCNPJ]=${cpfcnpj}`;
  return apiClient2.get(`${baseUrl}`);
}

export const getMultiTelephones = (id) => {
  const baseUrl = `/api/cliente/${id}/telefone`;
  return apiClient2.get(`${baseUrl}`);
};

export const createOrUpdateMultiTelephones = (data) => {
  const baseUrl = data.rowData.CODIGO 
  ? `api/cliente/${data.customerId}/telefone/edit` 
  : `api/cliente/${data.customerId}/telefone` ;
  return data.rowData.CODIGO ? apiClient.update(baseUrl, data) : apiClient.create(baseUrl, data);
};



export {
  getCustomersList,
  getCustomerDetails,
  saveCustomerDetails,
  getAddressByZipcode,
  getCustomer,
};
