import { X } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { TituloModal, BotaoEntrar, DivLoading } from "./style";
import { Col, Row, Modal, ModalBody, Spinner, Alert } from "reactstrap";
import { Formik, Form, useFormik } from "formik";
import { initialValuesToFormik, RegisterSchema } from "./mockAndValidation";
import { toast } from "react-toastify";
import { DadosDoCliente } from "./Sections/DadosDoCliente";
import { Contato } from "./Sections/Contato";
import { EnderecoFaturamento } from "./Sections/EnderecoFaturamento";
import { EnderecoEntrega } from "./Sections/EnderecoEntrega";

import { useSolar } from "../../../hooks";
import { apiClient } from "../../../api/apiCore";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataClientOrderChildren,
  getFreteValue,
} from "../../../store/actions";
import { BudgetActionTypes } from "../../../store/budget/actionTypes";

const ModalRegister = ({
  openModalRegisterClient,
  setOpenModalRegisterClient,
  id,
  data,
  codFilial,
}) => {
  const { customer, active } = useSelector(state => ({
    customer: state.Order.newOrder.newOrder.customer,
    active: state.Budget.active,
  }));
  const formRef = useRef();
  const [isOpenEnderecoEntrega, setIsOpenEnderecoEntrega] = useState(true);
  const isSolar = useSolar();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (props, { setSubmitting, resetForm }) => {
    if (isSolar) {
      if (
        active.data.orcamento.CepEnt != props.CEP_ENT.replace(/[^0-9]+/g, "")
      ) {
        const valTotal =
          parseFloat(active.data.orcamento.total) -
          parseFloat(active.data.orcamento.CJ_FRETE);

        const baseUrl = `/api/zipcode/${props.CEP_ENT.replace(
          /[^0-9]+/g,
          ""
        )}/${"02"}/${codFilial}/${valTotal}`;
        const OBJ = { previsao: {} };

        const resp = await apiClient.create(`${baseUrl}`, OBJ);
        var valTotalCep = resp.shippingMethods[0].price;
        var shippingDelay = resp.shippingMethods[0].delivery_delay;

        if (
          parseFloat(valTotalCep).toFixed(2) !=
          parseFloat(active.data.orcamento.CJ_FRETE).toFixed(2)
        ) {
          if (parseInt(active.data.prazoEntrega) != shippingDelay) {
            return toast.info(
              "Valor ou prazo do frete mudou e será preciso fazer um novo orcamento",
              { theme: "colored" }
            );
          }
        }
      }

      if (active.data.orcamento.UFEnt != props.UF_ENT) {
        return toast.info(
          "Valor ou prazo do frete mudou e será preciso fazer um novo orcamento",
          { theme: "colored" }
        );
      }
    }

    const obj = {
      client: {
        N_COMPLETO: props.N_COMPLETO,
        N_REDZ: props.N_REDZ,
        EMAIL: props.EMAIL,
        CONTATO: props.CONTATO,
        job_title: props.CARGO,
        DT_NASC: props.DT_NASC || "",
        CPFCNPJ: props.CPFCNPJ,
        social_number: props.TP_PESSOA == "J" ? props.Inscr : props.Rg,
        prospect_id: data?.data.orcamento?.prospect?.US_COD,
        parceiro: data?.data.orcamento?.vendedor3?.CODIGO
      },
      telephone: {
        DDD: props.DDD,
        TEL: props.TEL,
        DDD_CEL: props.DDD_CEL,
        CELULAR: props.CELULAR,
      },
      client_address: {
        CEP: props.CEP?.replace(/[^0-9]+/g, ""),
        UF: props.UF,
        MUNICIPIO: props.MUNICIPIO,
        BAIRRO: props.BAIRRO,
        ENDERECO: props.ENDERECO,
        NumEndCob: props.NumEndCob,
        COMPL: props.COMPL,
        CEP_ENT: props.CEP_ENT?.replace(/[^0-9]+/g, ""),
        UF_ENT: props.UF_ENT,
        MunicipEnt: props.MunicipEnt,
        BAI_ENT: props.BAI_ENT,
        END_ENT: props.END_ENT,
        NumEndEnt: isOpenEnderecoEntrega ? props.NumEndEnt : props.NumEndCob,
        COMPL_ENT: props.COMPL_ENT,
      },
      AGENCIA: "",
      CAPSOC: "",
      CNPJTRAB: "",
      CODIBGE: "",
      CODIBGEC: "",
      CODIBGEE: "",
      CPFREF: "",
      DTADMISS: "",
      EMPTRAB: "",
      FATMEN: "",
      FUNCEMP: "",
      HOMEPAGE: "",
      INSCRRUR: "",
      PROCESSO: "",
      SOCIO: "",
      TELREFBANC: "",
      vendedor2: "",
      contribution: props.Contrib,
      CODEMPRESA: isSolar ? "02" : "01",
      CODFILIAL: codFilial,
    };

    if (active?.data?.orcamentos_filhos?.length > 0) {
      dispatch(
        getDataClientOrderChildren(
          BudgetActionTypes.GET_DATA_CLIENT_ORDER_CHILDREN,
          obj
        )
      );
    }
    try {
      const response = await apiClient.create(
        `/api/orcamentos/efetiva/${id}`,
        obj
      );

      if (response?.erro == true || response?.success == true) {
        var errorMsg = response?.msg ? response.msg : response.message;
        return toast.error(
          `Ocorreu um erro tentar cadastrar um cliente ${errorMsg}`,
          {
            theme: "colored",
          }
        );
      }

      toast.success("Pedido efetivado com sucesso!", {
        theme: "colored",
      });

      history.push(
        isSolar
          ? `/solar/pedidos/${response?.pedido}/${codFilial}/order`
          : `/pedidos/${response?.pedido}/${codFilial}/order`
      );

      /* location.reload(); */
    } catch (err) {
      toast.error("Ocorreu um erro tentar cadastrar um cliente", {
        theme: "colored",
      });
    }

    resetForm();
    setOpenModalRegisterClient(false);
    setSubmitting(false);
  };

  const closeModal = () => {
    setOpenModalRegisterClient(!openModalRegisterClient);
  };

  useEffect(() => {
    if (!openModalRegisterClient) {
      setIsOpenEnderecoEntrega(true);
    }
  }, [openModalRegisterClient]);

  return (
    <Modal isOpen={openModalRegisterClient} size="lg" toggle={closeModal}>
      <Row style={{ marginBottom: 10 }}>
        <Col md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <X
            style={{ marginRight: 10, marginTop: 10, cursor: "pointer" }}
            size={20}
            color="#000"
            weight="duotone"
            onClick={closeModal}
          />
        </Col>
      </Row>
      {customer?.loading && (
        <DivLoading>
          <Spinner size="sm" />
        </DivLoading>
      )}
      <ModalBody>
        <Row>
          <Col md={12}>
            <TituloModal>Cadastro de Cliente</TituloModal>
          </Col>
        </Row>

        <Formik
          initialValues={initialValuesToFormik}
          validationSchema={RegisterSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            values,
            handleChange,
            handleBlur,
            setErrors,
            setTouched,
            resetForm,
          }) => (
            <Form
              autoComplete="off"
              style={{ maxWidth: 710, margin: "0 auto" }}
            >
              <DadosDoCliente
                errors={errors}
                touched={touched}
                values={values}
                data={data}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                setTouched={setTouched}
              />
              <Contato
                errors={errors}
                touched={touched}
                data={data}
                setFieldValue={setFieldValue}
              />
              <EnderecoFaturamento
                errors={errors}
                touched={touched}
                values={values}
                isOpenEnderecoEntrega={isOpenEnderecoEntrega}
                setFieldValue={setFieldValue}
              />
              <EnderecoEntrega
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                isOpenEnderecoEntrega={isOpenEnderecoEntrega}
                setIsOpenEnderecoEntrega={setIsOpenEnderecoEntrega}
              />
              {/*      {values.TP_PESSOA == "F" ? (
                <AnaliseDeCreditoF errors={errors} touched={touched} />
              ) : (
                <AnaliseDeCreditoJ errors={errors} touched={touched} />
              )} */}
              <Row>
                <Col
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <BotaoEntrar
                    isSolar={isSolar}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner size="sm" /> : "Enviar"}
                  </BotaoEntrar>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default withRouter(ModalRegister);
