import React from "react";
import { Col, FormGroup, Input, Row, Label } from "reactstrap";

const FutureSale = ({ futureSaleValue, setFutureSaleValue }) => {
  return (
    <Col>
      <Row>
        <h4
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#202833",
            marginBottom: 15,
          }}
        >
          Esse pedido é venda futura?
        </h4>
        <FormGroup check>
          <Row className="justify-content-start ms-1">
            <Col md={3}>
              <Input
                type={"radio"}
                name={"futureSale"}
                id={"futureSaleConfirm"}
                className={"modalidade"}
                value={futureSaleValue}
                onChange={() => setFutureSaleValue(!futureSaleValue)}
              />
              <Label
                htmlFor="futureSaleConfirm"
                className={futureSaleValue ? "label-prazo-active" : ""}
                style={{ marginLeft: 10, fontWeight: 400 }}
              >
                SIM
              </Label>
            </Col>
            <Col md={3}>
              <Input
                type={"radio"}
                name={"futureSale"}
                id={"futureSaleDeny"}
                className={"modalidade"}
                defaultChecked
                value={futureSaleValue}
                onChange={() => setFutureSaleValue(!futureSaleValue)}
              />
              <Label
                htmlFor="futureSaleDeny"
                className={!futureSaleValue ? "label-prazo-active" : ""}
                style={{ marginLeft: 10, fontWeight: 400 }}
              >
                NÃO
              </Label>
            </Col>
          </Row>
        </FormGroup>
      </Row>
    </Col>
  );
};

export default FutureSale;
