import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart, addStatus, addToCart, editQtdKits } from "../../../../store/actions";
import { toast } from "react-toastify";
import { getAcrescimoCount, valueByQTD, getOutOfStockMessage } from "../../../../utils";
import { apiClient } from "../../sunbeam-at-your-address/helpers/importer.helper";
import { arredondar, dateNow } from "../../../../utils";
import { useProfile } from "../../../../hooks";
import { isInverter } from "../../../utils/functions/solarUtils";
function ModalInsertProduct({
  isOpeModalInsertProduct,
  setIsOpenModalInsertProduct,
  addValTot,
  valores,
  moduleSelected,
  acrescimo,
  isMicroInverterSelected,
  setLoadingProductAddition
}) {
  const dispatch = useDispatch();
  const { newOrder, user } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      user: state.Profile.profileDetails.user,
    };
  });
  const { userProfile } = useProfile();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";
  const [data, setData] = useState({
    categoria: [{ id: 0, nome: "Carrengando..." }],
    produto: [],
    quantidade: 1,
  });
  const [categoriaSelect, setCategoriaSelect] = useState([
    [{ id: 0, name: "Carregando...", values: [] }],
  ]);
  const [produtoSelect, setProdutoSelect] = useState([
    { id: 0, name: "Carregando..." },
  ]);
  const [attListProducts, setAttListProducts] = useState(false);
  const [loading, setLoading] = useState(true);
  const userCode = user?.code ? user?.code : "";

  const toggle = () => setIsOpenModalInsertProduct(state => !state);

  const getProducts = async () => {
    setLoading(true);
    const resp = await apiClient.get(
      `/api/person-kits/listProducts/02/${isMicroInverterSelected}`
    );
    let newObj = [];

    Object.keys(resp).map((item, index) =>
      newObj.push({ id: index, nome: item, values: resp[item] })
    );
    setCategoriaSelect(newObj);
    setData(state => {
      return { ...state, categoria: newObj[0] ? [newObj[0]] : [] };
    });
    setLoading(false);
  };

  useEffect(() => {
    getProducts();
  }, [moduleSelected]);

  const modifyProductValues = () => {
    var subItems = data?.categoria[0]?.values;
    var arraySubitemsRepetitions = [];
    for (const value in subItems) {
      const countInverter = newOrder.addedItems.filter(product =>
        product.produto.nome == subItems[value].nome
      );

      if (countInverter.length == 0) {
        arraySubitemsRepetitions.push(subItems[value]);
      }
    }

    setProdutoSelect(arraySubitemsRepetitions);
    setData(state => {
      return {
        ...state,
        produto: arraySubitemsRepetitions[0]
          ? [arraySubitemsRepetitions[0]]
          : [],
      };
    });
  };

  useEffect(() => {
    if (data?.categoria[0]?.values?.length > 0) {
      modifyProductValues();
    }
  }, [data?.categoria[0]?.values, newOrder.addedItems, attListProducts]);

  const saveNewProduct = () => {
    setLoadingProductAddition(true);

    const stock = (data.produto[0].qtdEstoque + 1) - Number(data.quantidade);

    if (!Number.isInteger(Number(data.quantidade))) {
      return toast.error("Só são permitidas quantidades inteiras de produtos", {
        theme: "colored",
      });
    }

    if (data?.quantidade <= 0) {
      return toast.info("A quantidade precisa ser maior que 0", {
        theme: "colored",
      });
    } else if (stock < 0) {
      return toast.info(getOutOfStockMessage(userProfile.claims.groups), {
        theme: "colored",
      });
    }

    if (isInverter(data.produto[0])) {
      addToCartMC4();
    } else {
      addToCartProduct(stock);
    }
    toggle();
  };
  const addToCartMC4 = async () => {
    const countInverter = newOrder.addedItems.filter(product =>
      isInverter(product.produto[0])
    );

    let obj = {
      inverter: data.produto[0].nome,
      count: countInverter?.length || 0,
      qtd: Number(data.quantidade)
    };
    let dataProduct = {};
    let arrayProduct = [];

    const resp = await apiClient.create(`/api/person-kits/changeInverter/02/${isMicroInverterSelected}`, obj);

    if (resp.erro) {
      toast.info(resp.msg, {
        theme: "colored",
      });
    } else {
      toast.success(`${data.produto[0].nome} foi adicionado ao carrinho`, {
        theme: "colored",
      });
    }

    setLoadingProductAddition(false);
    
    var subTotal = valueByQTD(data.produto[0].precoCliente, data.quantidade);
    var resultAcrescimo = getAcrescimoCount(acrescimo, subTotal);
    var precoClienteAcrescimo = resultAcrescimo / data.quantidade;

    var subTotalMC4 = valueByQTD(resp.mc4.precoCliente, resp.mc4.qtd);
    var resultAcrescimoMC4 = getAcrescimoCount(acrescimo, subTotalMC4);
    var precoClienteAcrescimoMC4 = resultAcrescimoMC4 / resp.mc4.qtd;

    arrayProduct.push(
      {
        ...data.produto[0],
        pecasVinculadas: resp.pecasVinculadas,
        qtd: Number(data.quantidade),
        subTotal: resultAcrescimo,
        valorTabela: precoClienteAcrescimo,
        PRCRT: resultAcrescimo,
        precoClienteAcrescimo: precoClienteAcrescimo,
        id: countInverter?.length + 1,
        qtdEstoque: resp?.qtdEstoque,
        previsao: resp?.previsao,
      },
      {
        ...resp.mc4,
        qtd: resp.mc4.qtd * Number(data.quantidade),
        subTotal: resultAcrescimoMC4 * Number(data.quantidade),
        valorTabela: precoClienteAcrescimoMC4,
        PRCRT: resultAcrescimoMC4 * Number(data.quantidade),
        precoClienteAcrescimo: precoClienteAcrescimoMC4,
        id: countInverter?.length + 1,
        initialQtd: resp.mc4.qtd,
        previsao: resp.mc4.previsao
      }
    );

    const products = [];

    arrayProduct.map((item, k) => {
      dataProduct = {
        produto: item,
        promotionDiscount: 0
      };
      products.push(dataProduct);
    });

    dispatch(addItemsToCart(products));
    setAttListProducts(state => !state);
    addValTotalStringBoxAndMC4(arrayProduct);
  };

  const addValTotalStringBoxAndMC4 = arrayProduct => {
    let valTotal = {
      ...valores,
      [arrayProduct[0].nome]: arrayProduct[0].subTotal,
      [arrayProduct[1].nome]: arrayProduct[1].subTotal,
    };

    addValTot(valTotal);

    addStatusProduct();
  };

  const addToCartProduct = stock => {
    var subTotal = valueByQTD(data.produto[0].precoCliente, data.quantidade);
    var resultAcrescimo = getAcrescimoCount(acrescimo, subTotal);
    var precoClienteAcrescimo = parseFloat(
      arredondar(resultAcrescimo / Number(data.quantidade))
    );
    var total = precoClienteAcrescimo * Number(data.quantidade);
    let addQtd = {
      ...data.produto[0],
      qtd: Number(data.quantidade),
      PRCRT: total,
      subTotal: total,
      valorTabela: precoClienteAcrescimo,
      precoClienteAcrescimo: precoClienteAcrescimo,
      qtdEstoque: stock
    };
    let dataProduct = {
      produto: addQtd,
      promotionDiscount: 0
    };

    dispatch(addToCart(dataProduct));

    toast.success(`${data.produto[0].nome} foi adicionado ao carrinho`, {
      theme: "colored",
    });
    setAttListProducts(state => !state);
    addValTotal();
    setLoadingProductAddition(false);
  };

  const addValTotal = () => {
    let valTotal = {
      ...valores,
      [data.produto[0].nome]: valueByQTD(
        data.produto[0].precoCliente,
        data.quantidade
      ),
    };
    addValTot(valTotal);
    addStatusProduct();
  };
  const addStatusProduct = () => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();
    let status = {
      statusName: "add",
      name: data.produto[0].nome,
      date: date,
      hours: hours,
      userCode: userCode,
      displayName: displayName,
    };

    dispatch(addStatus(status));
  };

  return (
    <Modal
      className="modal-insert-product"
      isOpen={isOpeModalInsertProduct}
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>INSERIR PRODUTO</ModalHeader>
      <ModalBody>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner />
          </div>
        ) : (
          <Row>
            <Col md={5}>
              <Label>Categoria:</Label>
              <Typeahead
                id="category"
                labelKey={option => `${option.nome}`}
                onFocus={() => {
                  setData(state => {
                    return { ...state, categoria: [] };
                  });
                }}
                onChange={e => {
                  setData(state => {
                    return { ...state, categoria: e };
                  });
                }}
                selected={data.categoria}
                options={categoriaSelect}
                emptyLabel="Nenhum item encontrado"
              />
            </Col>

            <Col md={5}>
              <Label>Produto:</Label>
              <Typeahead
                id="product"
                labelKey={option => `${option.nome}`}
                onFocus={() => {
                  setData(state => {
                    return { ...state, produto: [] };
                  });
                }}
                onChange={e => {
                  setData(state => {
                    return { ...state, produto: e };
                  });
                }}
                selected={data.produto}
                options={produtoSelect}
                emptyLabel="Nenhum item encontrado"
              />
            </Col>
            <Col md={2}>
              <Label>Quantidade:</Label>
              <Input
                type="number"
                value={data.quantidade}
                onChange={e =>
                  setData(state => {
                    return { ...state, quantidade: e.target.value };
                  })
                }
              />
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Fechar
        </Button>{" "}
        <Button
          disabled={loading}
          onClick={saveNewProduct}
          className="button-save"
        >
          Adicionar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalInsertProduct;
