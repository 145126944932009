import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  EDIT_PROFILE,
  GET_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS,
  GET_CITY,
  GET_STATE
} from "./actionTypes";
import {
  profileSuccess,
  profileError,
  profileApiFail,
  profileApiSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  getProfileDetails as getProfileDetailsApi,
  updateProfileDetails as updateProfileDetailsApi,
  getCity as getCityApi,
  getState as getStateApi
} from "../../../api/index";
import { toast } from "react-toastify";

const fireBaseBackend = getFirebaseBackend();

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      );
      yield put(profileSuccess(response));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

function* getProfileDetails({ payload: filters }) {
  try {
    const response = yield call(getProfileDetailsApi, filters);
    yield put(profileApiSuccess(GET_PROFILE_DETAILS, response));
  } catch (error) {
    yield put(profileApiFail(GET_PROFILE_DETAILS, error));
  }
}

function* getCity({payload: number}) {
  try {
    const response = yield call(getCityApi, number);
    yield put(profileApiSuccess(GET_CITY, response));
  } catch (error) {
    yield put(profileApiFail(GET_CITY, error));
  }
}

function* getState({payload: number}) {
  try {
    const response = yield call(getStateApi, number);
    yield put(profileApiSuccess(GET_STATE, response));
  } catch (error) {
    yield put(profileApiFail(GET_STATE, error));
  }
}

function* updateProfileDetails({ payload: data }) {
  try {
    const response = yield call(updateProfileDetailsApi, data);
    if (response.success) {
      toast.success(`Informações do usuário atualizadas com sucesso.`, {
        theme: "colored",
      });
    }
    yield put(profileApiSuccess(UPDATE_PROFILE_DETAILS, response));
  } catch (error) {
    yield put(profileApiFail(UPDATE_PROFILE_DETAILS, error));
  }
}

export function* watchProfileEdit() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}
export function* watchGetProfileDetails() {
  yield takeEvery(GET_PROFILE_DETAILS, getProfileDetails);
}
export function* watchGetCity() {
  yield takeEvery(GET_CITY, getCity);
}
export function* watchGetState() {
  yield takeEvery(GET_STATE, getState);
}
export function* watchUpdateProfileDetails() {
  yield takeEvery(UPDATE_PROFILE_DETAILS, updateProfileDetails);
}

function* ProfileSaga() {
  yield all([
    fork(watchProfileEdit),
    fork(watchGetProfileDetails),
    fork(watchGetCity),
    fork(watchGetState),
    fork(watchUpdateProfileDetails),
  ]);
}

export default ProfileSaga;
