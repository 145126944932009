import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDataLayer } from "../hooks/DataLayerHooks";

export default function PageTracker() {
  const location = useLocation();
  const { pushToDataLayer } = useDataLayer();
  const [currentPathname, setCurrentPathname] = useState();

  useEffect(() => {
    if (currentPathname != location.pathname) {
      const trackPageView = () => {
        pushToDataLayer('page_view');
      }
      trackPageView();
      setCurrentPathname(location.pathname);
    }
  }, [location]);

  return null;
}