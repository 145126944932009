import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import SellerDataTable from "./SellerDataTable";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";

import SellersFilters from "./SellersFilters";
import { useSelector, useDispatch } from "react-redux";
import { listSellers } from "../../store/actions";
import { useSolar } from "../../hooks";
import DataTable from "react-data-table-component";
import { useState } from "react";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const Seller = () => {
  const dispatch = useDispatch();
  const solar = useSolar();
  const { list } = useSelector(state => ({
    list: state.Seller.list,
  }));
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState({
    cnpj: "",
    codigo: "",
    nome: "",
    codigo_sup: "",
    page: currentPage,
  });
  var perPage = 20;
  var total = list?.graph?.protheus || 0;
  useEffect(() => {
    dispatch(listSellers(filter));
  }, [dispatch]);

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(listSellers({ ...filter, page: page }));
  };

  const options = {
    responsive: true,
    plugins: {
      legend: false,
    },
  };

  const data = {
    labels: Object.keys(list.graph),
    datasets: [
      {
        data: Object.values(list.graph),
        backgroundColor: "rgb(247 209 98 / 60%)",
      },
    ],
  };

  const columns = [
    {
      name: "Código",
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <Link to={`${solar ? '/solar' : ''}/vendedores/${row.CODIGO}`} className="text-body fw-bold">
          {row.CODIGO}
        </Link>
      ),
    },
    {
      name: "Nome",
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => <span>{row.NREDUZ.trim()}</span>,
    },
    {
      name: "Telefone",
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <span>
          ({row.DDD_CEL || row.DDD_TEL}){" "}
          {row.CELULAR.trim() || row.TELEFONE.trim()}
        </span>
      ),
    },
    {
      name: "UF / Município",
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <span>
          {row.ESTADO} / {row.CIDADE.trim()}
        </span>
      ),
    },
    {
      name: "Nivel",
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => <span>{row.userClube?.groups[0]?.name || ""}</span>,
    },
    {
      name: "Consultor",
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => <span>{row.SUPERVISOR?.substr(0, 25) || "N/D"}</span>,
    },
    {
      name: "Data de Cadastro",
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => {
        const date = new Date(row.userClube?.created_at || null);
        return `${date.getDate().toString().padStart(2, "0")}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getFullYear()}`;
      },
    },
    {
      name: "Ultimo Acesso",
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => {
        const date = new Date(row.userClube?.last_login_on || null);
        return `${date.getDate().toString().padStart(2, "0")}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getFullYear()}`;
      },
    },
    {
      name: "",
      // eslint-disable-next-line react/display-name
      cell: row => (
        <>
          <Link to={`${solar ? '/solar' : ''}/vendedores/${row.CODIGO}`} className="p-2">
            <i className="ph-eye"></i>
          </Link>
          <Link to={`${solar ? '/solar' : ''}/vendedores/${row.CODIGO}/edit`} className="p-2">
            <i className="mdi mdi-pencil me-1"></i>
          </Link>
        </>
      ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Resultados por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: true,
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <h3 className="titulo-seller mb-4">Parceiro SUPERUSER</h3>
          <Row>
            <Col md={6}>
              <SellersFilters filter={filter} setFilter={setFilter} />
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h5
                    className="font-size-20"
                    style={{ color: solar ? "#FF601A" : "#2D567B" }}
                  >
                    VENDEDORES PROTHEUS VS. CLUBE...
                  </h5>
                  <hr />
                  <Bar options={options} data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <SellerDataTable data={list.sellers} /> */}

          <DataTable
            columns={columns}
            data={list.sellers}
            progressPending={list.loading}
            progressComponent={<Spinner style={{ margin: "50px 0" }} />}
            pagination
            paginationServer
            paginationTotalRows={total}
            paginationPerPage={perPage}
            onChangePage={handlePageChange}
            paginationComponentOptions={paginationComponentOptions}
          />
          {/*     )} */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Seller;
