import { apiClient2 } from "../api/apiCore2";
import { toast } from "react-toastify";
import { getProfileDetailsList } from "../store/actions";
import { useProfile } from "../hooks/UserHooks";
import { apiClient } from "../api/apiCore";
import {} from "react-router-dom";
export const chooseCompany = async (
  system,
  history,
  setLoadingChooseCompany
) => {
  const { setUserProfile } = useProfile();
  setLoadingChooseCompany({ loading: true, companyName: system });
  if (system == "adias") {
    //0102 MPS - CAMPO GRANDE
    const resp = apiClient2.get("/changecd/0102");
    await toast.promise(
      resp,
      {
        pending: "Aguarde!",
        success: "Empresa alterada com sucesso!",
        error: "Ouve um erro ao alterar a empresa!",
      },
      { theme: "colored" }
    );
    setUserProfile(state => {
      return {
        ...state,
        claims: { ...state.claims, company: "01" },
      };
    });

    history.push("/dashboard");
  }

  if (system == "solar") {
    //0202 EBRS - SOLAR
    const resp = apiClient2.get("/changecd/0202");
    await toast.promise(
      resp,
      {
        pending: "Aguarde!",
        success: "Empresa alterada com sucesso!",
        error: "Ouve um erro ao alterar a empresa!",
      },
      { theme: "colored" }
    );
    setUserProfile(state => {
      return {
        ...state,
        claims: { ...state.claims, company: "02" },
      };
    });
    history.push("/solar/ordenar-por-consumo");
  }

  setLoadingChooseCompany({ loading: false, companyName: system });
};

export const chooseCompanyInitial = async (
  system,
  history,
  dispatch,
  setLoadingChooseCompany
) => {
  setLoadingChooseCompany({ loading: true, companyName: system });
  try {
    if (system == "adias") {
      //0102 MPS - CAMPO GRANDE
      await apiClient2.get("/changecd/0102");

      dispatch(getProfileDetailsList());
      history.replace("/dashboard?open_modal=true");
    }

    if (system == "solar") {
      //0202 EBRS - SOLAR
      await apiClient2.get("/changecd/0202");

      dispatch(getProfileDetailsList());
      history.replace("/solar/?open_modal=true");
    }
  } catch (err) {
    console.log({err});
    toast.error( err? err:"Ocorreu um erro ao selecionar a empresa!", {
      theme: "colored",
    });
  }

  setLoadingChooseCompany({ loading: false, companyName: system });
};
var valOld = 0;
var val = 0;
export const checkingTheSameShippingCost = async (zipcode, valTot, company) => {
  const baseUrl = `/api/zipcode/${zipcode.replace(
    /[^0-9]+/g,
    ""
  )}/${company}/02/${valTot}`;
  const response = await apiClient.get(`${baseUrl}`);
  val = response.shippingMethods[0]?.price;
  if (valOld == val) {
    valOld = response.shippingMethods[0]?.price;
    return true;
  } else {
    valOld = response.shippingMethods[0]?.price;
    return false;
  }
};
